<template>
  <div class="schedule-post">
    <div v-if="isDateSelected" class="delete-button">
      <ion-button @click="clearSelectedDate()">
        <ion-icon :icon="closeOutline" />
      </ion-button>
    </div>
    <div class="timer-button">
      <ion-button fill="outline" @click="schedulePost">
        <icons-provider
          v-if="!selectedDate.length"
          slot="icon-only"
          :icon-props="{
            width: '22',
            height: '22',
            fill: 'var(--ion-color-medium)',
          }"
          :name="AppIconsEnum.Timer"
        />
        <span v-else class="wrap-text">{{ selectedDateFormat }}</span>
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonIcon, IonButton } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { computed, watch, ref } from 'vue';
import type { ComputedRef, PropType } from 'vue';

import { IconsProvider } from '@/components';
import { AppIconsEnum } from '@/enums';
import type { PostTypeActionEnum } from '@/enums';
import { appDatePickerPopover, DateHelper, formatDateHelper, useErrors } from '@/helpers';
import { useI18n } from '@/i18n';

// Props
defineProps({
  postType: {
    type: String as PropType<PostTypeActionEnum>,
    default: '',
  },
});

// Helpers
const { handleError } = useErrors();
const { t } = useI18n();

// Refs
const selectedDate = ref('');

const isDateSelected: ComputedRef<boolean> = computed(() => selectedDate.value !== '');

const selectedDateFormat: ComputedRef<string> = computed(() =>
  selectedDate.value === '' ? t('now') : formatDateHelper(selectedDate.value, 'short')
);

// Methods
const schedulePost = async (event: Event) => {
  const result = await appDatePickerPopover(
    event,
    DateHelper.addMinutesToDateInISO(new Date().toISOString(), 15),
    true,
    true,
    5,
    DateHelper.getDateInUtc(new Date())
  );
  if (result.data && !DateHelper.isDateInPast(result.data)) {
    selectedDate.value = result.data;
  } else if (result.data && DateHelper.isDateInPast(result.data)) {
    handleError(true, undefined, t('validations.pastDate'));
  }
};

const clearSelectedDate = () => {
  selectedDate.value = '';
};

// Watchers
watch(selectedDate, () => {
  emit('onSelectDate', selectedDate.value);
});

// Emits
const emit = defineEmits(['onSelectDate']);
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
ion-button {
  margin: 0;
  min-height: calc(app-padding(md) * 5);
}
.schedule-post {
  position: relative;
  display: flex;
}
.timer-button {
  position: relative;
}
.timer-button ion-button {
  --height: 100%;
  position: relative;
  --border-color: var(--ion-color-custom-element-lighter);
  --color: var(--ion-color-medium);
  width: 100%;
  --background-hover: var(--ion-color-custom-element-lighter);
  --border-radius: #{app-radius(md)};
}
.delete-button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.delete-button ion-button {
  --background: var(--ion-color-custom-element-lighter);
  --color: var(--ion-color-medium);
  --box-shadow: none;
  margin: 0;
  --border-radius: #{app-radius(md)};
}
.wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;

  @include respond-to-min-width(2xl) {
    font-size: 1rem;
  }
}
</style>
