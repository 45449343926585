import { openDB } from 'idb';
import { sha256 } from 'js-sha256';

import { isBlob } from './guards';
import { logErr, logInfo } from './logger';

/**
 * Initialize the IndexedDB database (create if it doesn’t exist)
 * @private
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB
 */
const dbPromise = openDB('image-cache-db', 1, {
  upgrade(db) {
    const store = db.createObjectStore('images');
    store.createIndex('timestamp', 'timestamp'); //?: Index for faster revalidation
  },
});

export type IImageCacheEntry = {
  blob: Blob;
  timestamp: number;
};

type IUseImages = {
  /**
   * Count cached images
   * @readonly
   */
  count: number;
  /**
   * Size cached images in Bytes
   * @readonly
   */
  size: number;
  /** Use it to clear all stored images */
  clearImages: () => Promise<void>;
  /**
   * ???
   *
   * @param canvas ???
   * @param image ???
   * @todo Add type
   */
  updateImageAfterCropping: (canvas: any, image: Blob) => Promise<File>;
  /**
   * Use it to fetch an image with a unique key and a function that fetches the image Blob
   * Function can hit cache | pending promise | fetch new promise
   * To avoid multiple fetches of the same image, Deferred (promise) pattern is used as well as Higher-Order Functions (passing a function as an argument)
   * It then caches the image and returns it's URL
   *
   * @param key Unique key for the image request
   * @param fetchFunction Function that fetches the image Blob
   * @returns URL of the image
   */
  fetchImage: (key: string, fetchFunction: () => Promise<Blob>) => Promise<string>;

  /**
   * Revalidate cache by removing expired items from IndexedDB.
   * Deletes cached items based on expiration threshold (CACHE_EXPIRATION_MS).
   */
  revalidateCache: () => Promise<void>;
};

let instance: IUseImages | null = null;

export function useImages(): IUseImages {
  if (instance) return instance;

  //#region Variables
  let count = 0; // Count cached images
  let size = 0; // Size cached images in KB
  const memoryCache = new Map<string, Blob>(); // In-memory cache
  const keyToHash = new Map<string, string>(); // Server-side key to hash
  const pendingRequests = new Map<string, Promise<Blob>>(); // Server-side key to Promise
  const CACHE_EXPIRATION_MS = 7 * 24 * 60 * 60 * 1000; // Cache expiration threshold - 1 week in milliseconds
  //#endregion

  //#region Private methods
  const _generateBinaryHash = async (blob: Blob): Promise<string> => {
    // Generate SHA-256 hash of binary data to avoid duplicates
    const arrayBuffer = await blob.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    return sha256(uint8Array);
  };

  /**
   * Recalculates and updates the cached image count and total size
   * by examining both In-memory and IndexedDB caches.
   */
  const _setCacheInfo = async (): Promise<void> => {
    // Calculate In-memory cache info
    const memoryCount = memoryCache.size;
    const memorySize = Array.from(memoryCache.values()).reduce((total, blob) => total + blob.size, 0);

    // Calculate IndexedDB cache info
    const db = await dbPromise;
    const dbKeys = await db.getAllKeys('images');
    const dbSize = (await Promise.all(dbKeys.map((key) => db.get('images', key)))).reduce(
      (total, item) => (item ? total + item.blob.size : total),
      0
    );

    // Update total count and size
    count = memoryCount + dbKeys.length;
    size = memorySize + dbSize;
  };

  // Upon module instantiation, initialize count and size immediately
  _setCacheInfo().catch((error) => logErr('Failed to initialize cache info', error));
  //#endregion

  //#region Public methods
  const clearImages = async (): Promise<void> => {
    logInfo(`ImagesHelper: clearImages()`); //! DEBUG
    count = 0;
    size = 0;
    memoryCache.clear();
    keyToHash.clear();
    pendingRequests.clear();
    const db = await dbPromise;
    try {
      await db.clear('images');
    } catch (e) {
      logErr('Failed to clear IndexedDB', e);
    }
  };

  const fetchImage = async (key: string, fetchFunction: () => Promise<Blob>): Promise<string> => {
    // Find the hash key for the server-side key
    const cachedHashKey = keyToHash.get(key);
    if (cachedHashKey) {
      const db = await dbPromise;

      // Load from IndexedDB if found
      try {
        const dbEntry: IImageCacheEntry | undefined = await db.get('images', cachedHashKey);
        if (dbEntry?.blob && isBlob(dbEntry.blob)) {
          memoryCache.set(cachedHashKey, dbEntry.blob);
          logInfo(`ImagesHelper: fetchImage(${cachedHashKey}) - found in IndexedDB`); //! DEBUG
          return URL.createObjectURL(dbEntry.blob);
        }

        // Load from In-memory if found
        if (memoryCache.has(cachedHashKey)) {
          logInfo(`ImagesHelper: fetchImage(${cachedHashKey}) - found in In-memory`); //! DEBUG
          const memoryBlob = memoryCache.get(cachedHashKey) as Blob; // Cast to Blob to avoid TS error
          return URL.createObjectURL(memoryBlob);
        }
      } catch (e) {
        logErr(`Error accessing IndexedDB or In-memory for key ${key}`, e);
      }
    }

    // Wait for pending request if found
    if (pendingRequests.has(key)) {
      logInfo(`ImagesHelper: fetchImage(${key}) - pending hit`); //! DEBUG
      const pendingBlob = (await pendingRequests.get(key)) as Blob; // Cast to Blob to avoid TS error
      return URL.createObjectURL(pendingBlob);
    }

    // Initiate a new fetch request
    const fetchPromise = fetchFunction()
      // When resolved:
      .then(async (blob) => {
        // Generate a binary hashKey for the blob
        const hashKey = await _generateBinaryHash(blob);

        // Store the hashKey in keyToHash map
        keyToHash.set(key, hashKey);

        // Size > 1MB: Store in IndexedDB
        if (blob.size > 1024 * 1024) {
          // Store in IndexedDB if > 1MB, including timestamp for revalidation
          const db = await dbPromise;
          try {
            const newEntry: IImageCacheEntry = { blob, timestamp: Date.now() };
            await db.put('images', newEntry, hashKey);
            logInfo(`ImagesHelper: fetchImage(${hashKey}) - stored in IndexedDB`); //! DEBUG
          } catch (e) {
            logErr(`Error storing in IndexedDB for key ${hashKey}`, e);
          }
        } else {
          // Size < 1MB: Store in memory cache
          memoryCache.set(hashKey, blob);
          logInfo(`ImagesHelper: fetchImage(${hashKey}) - stored in memory cache`); //! DEBUG
        }

        pendingRequests.delete(key);
        await _setCacheInfo();
        return blob;
      })
      .catch((e) => {
        logErr(`Failed to fetch image for key: ${key}`, e);
        pendingRequests.delete(key);
        throw e;
      });

    // Store the pending Promise
    pendingRequests.set(key, fetchPromise);
    const blob = await fetchPromise;
    return URL.createObjectURL(blob);
  };

  const revalidateCache = async (): Promise<void> => {
    const db = await dbPromise;

    try {
      const tx = db.transaction('images', 'readwrite');
      const store = tx.objectStore('images');

      const now = Date.now();
      const expiredKeys: string[] = [];

      // Collect expired keys
      for await (const cursor of store) {
        const entry = cursor.value;
        if (now - entry.timestamp > CACHE_EXPIRATION_MS) {
          expiredKeys.push(cursor.key as string);
        }
      }

      // Delete expired keys
      if (expiredKeys.length > 0) {
        logInfo(`Collected ${expiredKeys.length} expired keys, deleting...`); //! DEBUG
        expiredKeys.forEach(async (key) => {
          try {
            await store.delete(key);
          } catch (e) {
            logErr(`Failed to delete expired item with key: ${key}`, e);
          }
        });
        logInfo(`Deleted ${expiredKeys.length} expired keys successfully`); //! DEBUG
      }

      await _setCacheInfo(); // Update count and size after revalidation
    } catch (e) {
      logErr('Failed to revalidate cache', e);
    }
  };

  // Revalidate cache on module instantiation
  revalidateCache().catch((error) => logErr('Failed to revalidate cache', error));

  const updateImageAfterCropping = (canvas: any, image: Blob): Promise<File> => {
    // logInfo(`ImagesHelper: updateImageAfterCropping()`); //! DEBUG
    return new Promise((resolve, reject) => {
      const imageType = image.type;
      const imageExtension = imageType.split('/')[1];

      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          const filename = `image.${imageExtension}`;
          const changedImage = new File([blob], filename, { type: imageType });
          resolve(changedImage);
        } else {
          reject(new Error('Failed to create Blob from canvas'));
        }
      }, imageType);
    });
  };
  //#endregion

  instance = {
    get count() {
      return count;
    },
    get size() {
      return size;
    },
    clearImages,
    fetchImage,
    revalidateCache,
    updateImageAfterCropping,
  };

  return instance;
}
