<template>
  <Toaster>
    <template #success-icon>
      <icons-provider
        :icon-props="{
          width: '18',
          height: '18',
          fill: 'var(--ion-color-success)',
        }"
        :name="AppIconsEnum.CheckCircle"
      />
    </template>
    <template #error-icon>
      <icons-provider
        :icon-props="{
          width: '18',
          height: '18',
          fill: 'var(--ion-color-warning-shade)',
        }"
        :name="AppIconsEnum.Warning"
      />
    </template>
  </Toaster>
</template>

<script lang="ts" setup>
import { Toaster } from 'vue-sonner';

import { IconsProvider } from '@/components';
import { AppIconsEnum } from '@/enums';
</script>
