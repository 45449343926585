<template>
  <group-avatar
    slot="start"
    :title="group.title"
    :url="group.avatar?.url ?? ''"
    :group-type="group.type"
    :group-id="group.id"
    :is-official="group.isOfficial"
    :is-mandant="group.isMandant"
    :with-shadow="false"
  />
  <ion-label :class="['ion-text-wrap', size]" @click="$emit('onAction')">
    {{ group.title }}
    <p v-if="!statsDisabled" class="subtitle" v-html="useGroups().getGroupStats(group)" />
  </ion-label>
  <slot />
</template>

<script lang="ts" setup>
import { IonLabel } from '@ionic/vue';
import type { PropType } from 'vue';

import { GroupAvatar } from '@/components';
import { useGroups } from '@/helpers';
import type { GroupModel } from '@/types';

defineProps({
  group: {
    type: Object as PropType<GroupModel>,
    required: true,
  },
  statsDisabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<'small' | 'default'>,
    default: 'default',
  },
});

defineEmits(['onAction']);
</script>
<style scoped lang="scss">
ion-label {
  p {
    word-break: normal;
    &.subtitle {
      line-height: 1.2;
    }
  }
}
</style>
