<template>
  <div :class="withBorderRadius ? '' : 'no-border-radius'">
    <textarea ref="editorRef" />
  </div>
</template>

<script setup lang="ts">
import type { Editor, EditorEvent } from 'tinymce/tinymce';
import tinymce from 'tinymce/tinymce';
import type { PropType } from 'vue';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import 'tinymce/models/dom';
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/plugins/accordion';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/save';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/directionality';
import 'tinymce/icons/default/icons';
import { useRouter } from 'vue-router';

import { UploadFileTypes } from '@/enums';
import {
  componentDocsAttachment,
  componentDocsUploadFile,
  componentRichTextEditorFullscreenModal,
  useFilesHybrid,
  isNativeMobile,
  useSearchAutocomplete,
  useRichTextEditor,
} from '@/helpers';
import { isBlob, isDocArrayGuard, isFileGuard } from '@/helpers/guards';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useDocStore, useGroupsStore, useWikiStore } from '@/store';
import type { UserModel, DocModelWithType, FileModel, GroupModel, TopicModel } from '@/types';

// Props
const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  height: {
    type: Number,
    default: 500,
  },
  groupId: {
    type: null as unknown as PropType<number | null>,
    default: null,
  },
  isSimpleMode: {
    type: Boolean,
    default: false,
  },
  withBorderRadius: {
    type: Boolean,
    default: true,
  },
  isFullscreen: {
    type: Boolean,
    default: false,
  },
});

// Helpers
const richTextEditorHelper = useRichTextEditor();
const plugins = richTextEditorHelper.getPlugins(props.isSimpleMode);
const toolbar = richTextEditorHelper.getToolbar(props.isSimpleMode, props.isFullscreen);

// Router
const router = useRouter();

// Store
const docStore = useDocStore();
const wikiStore = useWikiStore();

// Refs
const editorRef = ref<HTMLElement | null>(null);
const editorInstance = ref<Editor | null>(null);
const isInternalUpdate = ref(false);
const currentPickerCallback = ref<any>(null);

// Methods
const selectFiles = async () => {
  const dialogBlocks = document.querySelectorAll('.tox-tinymce-aux') as NodeListOf<HTMLElement>;
  for (const dialogBlock of dialogBlocks) dialogBlock.style.visibility = 'hidden';

  const result = await componentDocsAttachment(null);

  const file = result.data?.[0] as DocModelWithType<FileModel>;

  if (file?.data?.mimeType.startsWith('image/') || file?.data?.mimeType.startsWith('video/')) {
    try {
      await setMediaOnTinyMce(file.data);
    } catch (error) {
      console.error('[TinyMCE] An error occurred while processing a file:', error);
    }
  }

  for (const dialogBlock of dialogBlocks) dialogBlock.style.visibility = 'visible';
};

const uploadImageFromDevice = async () => {
  const dialogBlocks = document.querySelectorAll('.tox-tinymce-aux') as NodeListOf<HTMLElement>;
  for (const dialogBlock of dialogBlocks) dialogBlock.style.visibility = 'hidden';

  const files = await componentDocsUploadFile(
    UploadFileTypes.SingleImage,
    undefined,
    undefined,
    undefined,
    props.groupId ? useGroupsStore().getGroupById(props.groupId) : undefined
  );

  if (!files || !isDocArrayGuard(files)) return;

  try {
    const file = files[0].data;

    if (!isFileGuard(file)) return;

    if (file) await setMediaOnTinyMce(file);
  } catch (error) {
    console.error('[TinyMCE] An error occurred while processing a file:', error);
  }

  for (const dialogBlock of dialogBlocks) dialogBlock.style.visibility = 'visible';
};

const uploadVideoFromDevice = async () => {
  const dialogBlocks = document.querySelectorAll('.tox-tinymce-aux') as NodeListOf<HTMLElement>;
  for (const dialogBlock of dialogBlocks) dialogBlock.style.visibility = 'hidden';

  const files = await componentDocsUploadFile(
    UploadFileTypes.SingleVideo,
    undefined,
    undefined,
    undefined,
    props.groupId ? useGroupsStore().getGroupById(props.groupId) : undefined
  );

  if (!files || !isDocArrayGuard(files)) return;

  try {
    const file = files[0].data;

    if (!isFileGuard(file)) return;

    if (file) await setMediaOnTinyMce(file);
  } catch (error) {
    console.error('[TinyMCE] An error occurred while processing a file:', error);
  }

  for (const dialogBlock of dialogBlocks) dialogBlock.style.visibility = 'visible';
};

const setMediaOnTinyMce = async (file: FileModel | undefined) => {
  if (file) {
    isInternalUpdate.value = true;

    currentPickerCallback.value(file.apiUrl, {
      alt: file.name,
    });
  }
};

type ProgressFn = (percent: number) => void;
type BlobInfo = {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
};

const imagesUploadHandler = async (blobInfo: BlobInfo, progress: ProgressFn): Promise<string> => {
  const imageBlob = blobInfo.blob();

  if (!isBlob(imageBlob)) {
    console.error('The uploaded file is not a Blob');
    return '';
  }

  // Displaying the percentage is hidden by the styles .tox-progress-bar.tox-progress-indicator
  if (progress) {
    progress(0);
  }

  const uploadFile = await useFilesHybrid().uploadFile({
    blob: imageBlob,
    name: blobInfo.filename(),
    size: imageBlob.size,
    mimeType: imageBlob.type,
  });

  if (uploadFile) {
    await docStore.createFiles([uploadFile], null, props.groupId);
    return 'ok';
  } else {
    return '';
  }
};

const tinyMceSetupFunction = (editor: Editor): void => {
  // Save editor instance to reactive variable
  editorInstance.value = editor;

  // Set initial content on editor init
  editor.on('init', () => {
    //TODO: Make sanitization og props.value here
    editorInstance.value?.setContent(props.value);
  });

  // Handle content input changes
  const handleContentChange = () => {
    // ensureLTRDirectionality(editor); // Ensure LTR on every content change
    const content = editorInstance.value?.getContent();
    isInternalUpdate.value = true;
    emit('update:value', content);
  };

  const onClick = (event: EditorEvent<MouseEvent>) => {
    const target = event.target as HTMLElement;
    const pattern = /^[ugt]:\d+$/;
    const href = target.getAttribute('href');

    if (!href) {
      console.warn('No href attribute present - no action will be taken');
      return;
    }

    if (target.tagName === 'A' && pattern.test(href)) {
      event.preventDefault();
      const [identifier, id] = href.split(':');

      const routerNameMapper = {
        u: ROUTES_NAME.USER_BY_ID,
        g: ROUTES_NAME.GROUP_BY_ID,
        t: ROUTES_NAME.TOPIC_BY_ID,
      };
      const routerLink = router.resolve({
        name: routerNameMapper[identifier as 'g' | 't' | 'u'],
        params: { id },
      });

      isNativeMobile ? router.push(routerLink) : window.open(routerLink.href, '_blank');
    }
  };

  const onOpenWindow = () => {
    const dialogTitleBlock = document.querySelector('.tox-dialog__title');
    const dialogBrowseUrls = document.querySelectorAll('.tox-dialog .tox-browse-url') as NodeListOf<HTMLElement>;

    const mode = dialogTitleBlock?.textContent === 'Insert/Edit Image' ? 'image' : 'media';

    // Hiding the "Browse files" button
    if (mode === 'image' || mode === 'media') {
      for (const dialogBrowseUrl of dialogBrowseUrls) {
        dialogBrowseUrl.style.display = 'none';
      }

      // Add "Select file" and "Upload file" links
      const addFileLinks = () => {
        const { t } = useI18n();
        const wrapper = document.createElement('div');
        wrapper.style.cssText = `
          margin: var(--app-md-padding) 0;
          width: 100%;
          display: flex;
          gap: var(--app-md-padding);
          justify-content: space-between;
        `;
        const formGroupFirst = document.querySelector('.tox-form__group');
        if (formGroupFirst) formGroupFirst.appendChild(wrapper);

        // Function to create a link element
        const createLink = (text: string, callback: () => void) => {
          const link = document.createElement('a');
          link.textContent = text;
          link.style.cssText = 'float: left;';

          link.addEventListener('click', async () => {
            callback();
          });

          return link;
        };

        // Adding 2 links to the wrapper
        wrapper.appendChild(createLink(t('files.networkOrGroupsFiles'), selectFiles));
        wrapper.appendChild(
          createLink(t('files.uploadFile.title'), mode === 'image' ? uploadImageFromDevice : uploadVideoFromDevice)
        );

        // ???
        const uploadElements = document.querySelectorAll('.js-wiki-edit-upload');
        uploadElements.forEach((uploadElement) => {
          const clonedUploadElement = uploadElement.cloneNode(true) as HTMLElement;
          clonedUploadElement.classList.remove('g-hidden');
          wrapper.appendChild(clonedUploadElement);
        });
      };

      // Add custom elements
      addFileLinks();

      const dialogBodyNav = document.querySelector('.tox-dialog__body-nav') as HTMLElement;
      if (dialogBodyNav) {
        dialogBodyNav.style.display = 'none';
      }

      for (const dialogBrowseUrl of dialogBrowseUrls) {
        dialogBrowseUrl.click();
      }
    }
  };

  // Set listeners for input and change events
  editor.on('input', handleContentChange);
  editor.on('change', handleContentChange);

  // Handle click on links
  editor.on('click', onClick);

  // Customize dialog for inserting/editing images and media
  editor.on('OpenWindow', onOpenWindow);

  // Add custom Fullscreen button
  editor.ui.registry.addButton('customFullscreenButton', {
    icon: 'fullscreen',
    onAction: async () => {
      if (editor.queryCommandState('ToggleToolbarDrawer')) {
        editor.execCommand('ToggleToolbarDrawer');
      }
      const result = await componentRichTextEditorFullscreenModal(props.value, props.groupId);
      if (result.data) {
        editorInstance.value?.setContent(result.data);
      }
    },
  });

  // Add autocompleter for user mentions
  editor.ui.registry.addAutocompleter('user-mention', {
    trigger: '@',
    minChars: 1,
    columns: 1,
    onAction: (autocompleteApi: any, rng: Range, value: string, meta: Record<string, string>) => {
      if (meta.type === 'divider') {
        return;
      }

      const parts = value.split('|');
      const name = parts[0];
      const id = parts[1];
      const hrefUrl = meta.type === 'users' ? `u:${id}` : `g:${id}`;
      const link = `<a href="${hrefUrl}">@${name}</a>`;

      if (meta.type === 'users') {
        wikiStore.setMentionedUserId([Number(id)]);
      }

      editor.selection.setRng(rng);
      editor.insertContent(link);
      autocompleteApi.hide();
    },
    fetch: async (pattern: string): Promise<any> => {
      const userAutoComplete = useSearchAutocomplete('user');
      const groupAutoComplete = useSearchAutocomplete('group');

      const responses = await Promise.all([
        userAutoComplete.autocomplete(pattern),
        groupAutoComplete.autocomplete(pattern),
      ]);

      const users: UserModel[] | null = responses[0];
      const groups: GroupModel[] | null = responses[1];

      const results = [];
      const { t } = useI18n();

      if (users?.length) {
        results.push({
          type: 'divider',
          value: '',
          classes: 'header',
          text: t('users.title'),
          meta: {
            type: 'divider',
          },
        });
        results.push(
          ...users.map((user) => ({
            type: 'cardtext',
            value: `${user.fullName}|${user.id}`,
            text: user.fullName,
            meta: {
              type: 'users',
            },
          }))
        );
      }

      if (groups?.length) {
        results.push({
          type: 'divider',
          value: '',
          text: t('appMenu.groups'),
          classes: 'header',
          meta: {
            type: 'divider',
          },
        });
        results.push(
          ...groups.map((group) => ({
            type: 'cardtext',
            value: `${group.mainAlias}|${group.id}`,
            text: group.mainAlias,
            meta: {
              type: 'groups',
            },
          }))
        );
      }

      return results;
    },
  });

  // Add autocompleter for tag mentions
  editor.ui.registry.addAutocompleter('tag-mention', {
    trigger: '#',
    minChars: 1,
    columns: 1,
    onAction: (autocompleteApi: any, rng: Range, value: string) => {
      const parts = value.split('|');
      const title = parts[0];
      const hrefUrl = `t:${parts[1]}`;
      const link = `<a href="${hrefUrl}">#${title}</a>`;

      editor.selection.setRng(rng);
      editor.insertContent(link);
      autocompleteApi.hide();
    },
    fetch: async (pattern: string): Promise<any> => {
      const topicAutocomplete = useSearchAutocomplete('topic');

      const topics: TopicModel[] | null = await topicAutocomplete.autocomplete(pattern);
      return topics?.map((topic) => ({
        type: 'cardtext',
        value: `${topic.title}|${topic.id}`,
        text: topic.title,
      }));
    },
  });
};

// TinyMCE init
const tinyMceInit = async () => {
  const editorLanguage = await richTextEditorHelper.setEditorLanguage();
  tinymce.init({
    target: editorRef.value as HTMLElement,
    height: props.height,
    menubar: false,
    statusbar: false,
    relative_urls: false,
    remove_script_host: true,
    skin: false,
    content_css: false,
    plugins: plugins,
    content_style: `
      body { font-family: Roboto, sans-serif; }
      h1,h2,h3,h4,h5,h6 { font-family: Roboto, sans-serif; }
    `,
    toolbar: toolbar,
    paste_data_images: true,
    // Functions
    images_upload_handler: imagesUploadHandler,
    setup: tinyMceSetupFunction,
    file_picker_callback: (callback) => {
      currentPickerCallback.value = callback;
    },
    //NOTE: Links settings
    default_link_target: '_blank',
    link_default_protocol: 'https',
    link_assume_external_targets: true,
    link_context_toolbar: true,
    target_list: false,
    link_title: false,
    directionality: 'ltr',
    // xss_sanitization: true,
    language: editorLanguage,
  });
};

// Watchers
watch(
  () => props.value,
  () => {
    if (editorInstance.value && (wikiStore.isDraftDeleted || !isInternalUpdate.value)) {
      editorInstance.value.setContent(props.value);
    }

    isInternalUpdate.value = false;
  }
);

// Lifecycle
onMounted(async () => {
  await tinyMceInit();
  emit('editor-ready');
});

onBeforeUnmount(() => {
  if (editorInstance.value) {
    editorInstance.value.destroy();
  }
});

// Emits
const emit = defineEmits(['update:value', 'editor-ready']);
</script>

<style scoped>
.no-top-borders {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
