import type { OverlayEventDetail, PositionSide } from '@ionic/core';
import { popoverController } from '@ionic/vue';
/* import { useStyleTag } from '@vueuse/core'; */
import { defineAsyncComponent /* ref */ } from 'vue';

/* import { isNativeIOS } from './helper'; */

import { isFileGuard, isWikiGuard } from './guards';

import type {
  MessengerListActionEnum,
  FileMenuActionEnum,
  WikiMenuActionEnum,
  DocsFilterTypeEnum,
  PostUploadFileEnum,
  AppImageChangeMenuEnum,
  PostTextActionEnum,
  CalendarCellActionEnum,
  CalendarViewModeEnum,
  CommentActionEnum,
  EventCalendarSourceEnum,
  IdeaStatusEnum,
  TaskManagementColumnActionEnum,
  customPageShowTypeEnum,
  WidgetCalendarPeriodEnum,
  CountriesEnum,
  PostsFilterEnum,
  AppMenuEnum,
  GroupsFilterEnum,
  UsersFilterEnum,
  AppBarEnum,
  TaskManagementTasksSortByEnum,
  TaskManagementTasksPageTypeEnum,
  TaskManagementProjectsSortByEnum,
  TopicsFilterEnum,
  AdminUserManagementMenuEnum,
  WikiDeleteOptionsEnum,
  FeedFilterTypeEnum,
  WikiEditControlsEnum,
  CountryCodesEnum,
  SortingTypeEnum,
} from '@/enums';
import { DocumentTypeEnum } from '@/enums';
import { useFileActions } from '@/helpers/useFileActionsHelper';
import { useWiki } from '@/helpers/useWikiHelper';
import type {
  AppMenuItem,
  AppMenuButtonsModel,
  DocModel,
  EventTimeModel,
  MessageChainModel,
  TaskManagementTaskModel,
  TaskManagementMilestoneModel,
  TopicModel,
  UserShortModel,
  TaskManagementColumnModel,
  TaskManagementProjectModel,
  TabCategories,
  PageModel,
  DocsMenuItemModel,
  WikiActionsMenuModel,
} from '@/types';

/**
 * --------------------------------------------------------------------------------
 * Common
 * --------------------------------------------------------------------------------
 */

export const appSortingSelectPopover = async (
  ev: Event,
  sortingTypes: SortingTypeEnum[],
  currentSorting: SortingTypeEnum
): Promise<SortingTypeEnum | undefined> => {
  const AppSortingSelect = defineAsyncComponent(() => import('@/components/Common/AppSortingSelectPopover.vue'));
  const popover = await popoverController.create({
    component: AppSortingSelect,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
    componentProps: {
      sortingTypes,
      currentSorting,
    },
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<SortingTypeEnum | undefined>) => {
    return result.data;
  });
};

export const appImageChangeMenuPopover = async (
  ev: Event,
  actions: AppMenuButtonsModel[]
): Promise<OverlayEventDetail<AppImageChangeMenuEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppImageChangeMenuPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    translucent: true,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
    componentProps: { actions },
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<AppImageChangeMenuEnum | undefined>) => {
    return result;
  });
};

export const appDatePickerPopover = async (
  ev: Event,
  date: string | null,
  yearsEnabled: boolean,
  timesEnabled = false,
  minutesIncrement = 15,
  minDate = ''
): Promise<OverlayEventDetail<string | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppDatePickerPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    /* reference: 'trigger', */
    dismissOnSelect: false,
    componentProps: {
      date,
      yearsEnabled,
      timesEnabled,
      minutesIncrement,
      minDate,
    },
    cssClass: ['custom-popover', 'centered-popover'],
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<string | undefined>) => {
    return result;
  });
};

export const appRightMenuPopover = async (ev: Event): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppRightMenuPopover.vue'));
  const target = ev.currentTarget || ev.target;
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: Object.assign({}, ev, { target }),
    side: 'bottom',
    dismissOnSelect: false,
    alignment: 'end',
    showBackdrop: false,
    cssClass: ['custom-popover', 'custom-menu-popover'],
  });
  await popover.present();
};

export const appCountrySelectPopover = async (
  ev: Event
): Promise<OverlayEventDetail<{ country: CountriesEnum; code: CountryCodesEnum } | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppCountrySelectPopover.vue'));
  const target = ev.currentTarget || ev.target;
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: Object.assign({}, ev, { target }),
    reference: 'trigger',
    dismissOnSelect: false,
    alignment: 'start',
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<{ country: CountriesEnum; code: CountryCodesEnum } | undefined>) => {
      return result;
    });
};

export const appFeedTypeSelectPopover = async (
  ev: Event,
  selectedFeedType: FeedFilterTypeEnum
): Promise<OverlayEventDetail<FeedFilterTypeEnum | undefined | null>> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppFeedTypeSelectPopover.vue'));
  const target = ev.currentTarget || ev.target;
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: Object.assign({}, ev, { target }),
    componentProps: { ev: Object.assign({}, ev, { target }), selectedFeedType },
    reference: 'trigger',
    dismissOnSelect: false,
    side: 'bottom',
    alignment: 'center',
    showBackdrop: false,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<FeedFilterTypeEnum | undefined | null>) => {
    return result;
  });
};

export const appFeedFilterSelectPopover = async (ev: any): Promise<OverlayEventDetail<PostsFilterEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppFeedFilterSelectPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    dismissOnSelect: true,
    side: 'bottom',
    alignment: 'center',
    showBackdrop: false,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<PostsFilterEnum | undefined>) => {
    return result;
  });
};

export const appSubMenuPopover = async (ev: Event, subMenuItems: AppMenuItem[], side: PositionSide): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppSubMenuPopover.vue'));
  const target = ev.currentTarget || ev.target;
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: Object.assign({}, ev, { target }),
    side: side,
    dismissOnSelect: false,
    alignment: 'center',
    showBackdrop: false,
    componentProps: { subMenuItems },
    id: 'app-submenu-popover',
    cssClass: side === 'bottom' ? ['custom-menu-popover'] : ['custom-menu-popover', 'centered-popover'],
  });

  //TODO: This code moves the popover by calculating its height
  /* if (side === 'top') {
    const offset = ref<number>(0);
    const { unload, css } = useStyleTag(`.custom-menu-popover {opacity:0;}`);
    await popover.present().then(async () => {
      const popoverElement = document.querySelector(
        '.custom-menu-popover'
      ) as HTMLElement;
      if (popoverElement) {
        const popoverViewport =
          popoverElement.querySelector('.popover-viewport');
        if (popoverViewport) {
          offset.value = isNativeIOS ? 8 : popoverViewport.clientHeight + 8;
          css.value = `.custom-menu-popover {--offset-y: -${offset.value}px !important;}`;
        }
      }
    });

    popover.onDidDismiss().then(() => {
      unload();
    });
  } else {
    await popover.present();
  } */

  await popover.present();
};

export const appHoursIntervalsPopover = async (
  ev: Event,
  activeTime: EventTimeModel
): Promise<OverlayEventDetail<EventTimeModel | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppHoursIntervalsPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
    reference: 'event',
    componentProps: { activeTime },
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<EventTimeModel | undefined>) => {
    return result;
  });
};

export const appBarFilterPopover = async (
  ev: any,
  pageFlag: AppMenuEnum
): Promise<
  OverlayEventDetail<
    GroupsFilterEnum | UsersFilterEnum | TaskManagementTasksPageTypeEnum | TopicsFilterEnum | undefined
  >
> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppBar/AppBarFilterPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    componentProps: { pageFlag },
    reference: 'trigger',
    dismissOnSelect: true,
    side: 'bottom',
    alignment: 'end',
    showBackdrop: false,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<
          GroupsFilterEnum | UsersFilterEnum | TaskManagementTasksPageTypeEnum | TopicsFilterEnum | undefined
        >
      ) => {
        return result;
      }
    );
};

export const appBarSortingPopover = async (
  ev: any,
  pageFlag: AppMenuEnum
): Promise<OverlayEventDetail<TaskManagementTasksSortByEnum | TaskManagementProjectsSortByEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppBar/AppBarSortingPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    componentProps: { pageFlag },
    reference: 'trigger',
    dismissOnSelect: true,
    side: 'bottom',
    alignment: 'end',
    showBackdrop: false,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<TaskManagementTasksSortByEnum | TaskManagementProjectsSortByEnum | undefined>
      ) => {
        return result;
      }
    );
};

export const appBarMobilePopover = async (
  ev: any,
  pageFlag: AppMenuEnum
): Promise<OverlayEventDetail<AppBarEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppBar/AppBarMobilePopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    componentProps: { pageFlag },
    reference: 'trigger',
    dismissOnSelect: true,
    side: 'bottom',
    alignment: 'end',
    showBackdrop: false,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<AppBarEnum | undefined>) => {
    return result;
  });
};

/**
 * --------------------------------------------------------------------------------
 * Specific
 * --------------------------------------------------------------------------------
 */

export const userListPopover = async (
  ev: Event,
  users: UserShortModel[],
  withSearch?: boolean,
  groupId?: number | undefined,
  taskData?: TaskManagementTaskModel | undefined
): Promise<OverlayEventDetail<UserShortModel | undefined>> => {
  const userList = defineAsyncComponent(() => import('@/components/Users/UserListPopover.vue'));
  const popover = await popoverController.create({
    component: userList,
    event: ev,
    mode: 'md',
    componentProps: { usersData: users, withSearch, groupId, taskData },
    dismissOnSelect: false,
    reference: 'trigger',
    cssClass: ['custom-popover', 'centered-popover', 'stretched-height'],
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<UserShortModel | undefined>) => {
    return result;
  });
};

export const messengerListContextMenu = async (
  ev: Event,
  chain: MessageChainModel,
  isHeader: boolean
): Promise<OverlayEventDetail<MessengerListActionEnum | undefined>> => {
  const messengerListMenu = defineAsyncComponent(() => import('@/components/Messenger/MessengerListMenu.vue'));
  const popover = await popoverController.create({
    component: messengerListMenu,
    componentProps: {
      chain,
      isHeader,
    },
    event: ev,
    translucent: true,
    dismissOnSelect: true,
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<MessengerListActionEnum | undefined>) => {
    return result;
  });
};

/* export const messengerContextMenu = async (
  ev: Event,
  id: number,
  editable: boolean,
  authorId: number,
  currentId: number | undefined
): Promise<OverlayEventDetail<MessageActionEnum | undefined>> => {
  const messengerMenu = defineAsyncComponent(
    () => import('@/components/Messenger/MessengerMenu.vue')
  );
  const popover = await popoverController.create({
    component: messengerMenu,
    componentProps: {
      id: id,
      isEditable: editable,
      isAuthor: authorId === currentId,
    },
    mode: 'md',
    translucent: true,
  });
  await popover.present();

  return popover
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<MessageActionEnum | undefined>) => {
      return result;
    });
}; */

export const fileContextMenu = async (ev: Event): Promise<OverlayEventDetail<FileMenuActionEnum | undefined>> => {
  const fileMenu = defineAsyncComponent(() => import('@/components/Feed/FeedFileMenu.vue'));
  const popover = await popoverController.create({
    component: fileMenu,
    mode: 'md',
    event: ev,
    translucent: true,
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<FileMenuActionEnum | undefined>) => {
    return result;
  });
};

export const docBrowserContextMenu = async (
  ev: Event,
  doc: DocModel
): Promise<OverlayEventDetail<FileMenuActionEnum | WikiMenuActionEnum | undefined>> => {
  const isWiki: boolean = doc.documentType === DocumentTypeEnum.Wiki;
  const isFile: boolean = doc.documentType === DocumentTypeEnum.File;
  // const isFolder: boolean = doc.documentType === DocumentTypeEnum.Folder;

  let menuItems: WikiActionsMenuModel[] | DocsMenuItemModel[] = [];
  if (isWiki && isWikiGuard(doc.data)) {
    menuItems = useWiki().getActionsMenuItems(doc.data);
  } else if (isFile && isFileGuard(doc.data)) {
    menuItems = useFileActions().getActionsMenuItems(doc);
  }

  if (menuItems.length === 0) {
    return { data: undefined };
  }

  const docsMenu = defineAsyncComponent(() => import('@/components/Docs/DocsActionsMenuPopover.vue'));
  const popover = await popoverController.create({
    component: docsMenu,
    mode: 'md',
    event: ev,
    translucent: true,
    cssClass: ['custom-popover', 'centered-popover'],
    componentProps: {
      menuItems,
    },
  });
  await popover.present();

  return await popover.onDidDismiss<FileMenuActionEnum | WikiMenuActionEnum | undefined>();
};

export const docBrowserFilterPopover = async (ev: Event, filterType: DocsFilterTypeEnum): Promise<void> => {
  const docsFilterItem = defineAsyncComponent(() => import('@/components/Docs/DocsFilterItem.vue'));
  const popover = await popoverController.create({
    component: docsFilterItem,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
    componentProps: {
      filterType,
    },
  });
  await popover.present();
};

export const postUploadFileMenu = async (
  ev: Event,
  groupId: number | null
): Promise<OverlayEventDetail<PostUploadFileEnum | undefined>> => {
  const fileMenu = defineAsyncComponent(() => import('@/components/Feed/FeedAttachmentFilePopover.vue'));
  const popover = await popoverController.create({
    component: fileMenu,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
    cssClass: ['custom-popover', 'centered-popover'],
    componentProps: {
      groupId,
    },
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<PostUploadFileEnum | undefined>) => {
    return result;
  });
};

export const wikiReplaceActionsMenu = async (): Promise<OverlayEventDetail<WikiDeleteOptionsEnum | undefined>> => {
  const fileMenu = defineAsyncComponent(() => import('@/components/Wikis/WikiReplaceActionsPopover.vue'));
  const popover = await popoverController.create({
    component: fileMenu,
    mode: 'md',
    translucent: true,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<WikiDeleteOptionsEnum | undefined>) => {
    return result;
  });
};

export const docBrowserFilterSelect = async (ev: Event): Promise<void> => {
  const DocsFilterSelect = defineAsyncComponent(() => import('@/components/Docs/DocsFilterSelectPopover.vue'));
  const popover = await popoverController.create({
    component: DocsFilterSelect,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
  });
  await popover.present();
};

export const postTextMenu = async (
  ev: Event | undefined
): Promise<OverlayEventDetail<PostTextActionEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Feed/FeedPostTextMenu.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
    reference: 'event',
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<PostTextActionEnum | undefined>) => {
    return result;
  });
};

export const commentContextMenu = async (
  authorId: number,
  currentUserId: number,
  isEditable: boolean,
  ev: Event
): Promise<OverlayEventDetail<CommentActionEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Feed/FeedCommentMenu.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    translucent: true,
    cssClass: 'custom-popover',
    reference: 'event',

    componentProps: {
      authorId,
      currentUserId,
      isEditable,
    },
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<CommentActionEnum | undefined>) => {
    return result;
  });
};

export const calendarCellMenu = async (
  ev: Event | undefined,
  activeView: CalendarViewModeEnum,
  isSmall: boolean
): Promise<OverlayEventDetail<CalendarCellActionEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Calendar/CalendarCellMenu.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
    reference: 'event',
    componentProps: { activeView, isSmall },
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<CalendarCellActionEnum | undefined>) => {
    return result;
  });
};

export const calendarEventListPopover = async (ev: Event, events: any): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Calendar/CalendarEventListPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    componentProps: { eventsData: events },
    dismissOnSelect: true,
  });
  await popover.present();
};

export const calendarSourcePopover = async (
  ev: Event
): Promise<OverlayEventDetail<EventCalendarSourceEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Calendar/CalendarSourcePopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    dismissOnSelect: true,
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<EventCalendarSourceEnum | undefined>) => {
    return result;
  });
};

export const calendarPeriodPopover = async (
  ev: Event
): Promise<OverlayEventDetail<{ title: string; value: WidgetCalendarPeriodEnum } | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Calendar/CalendarPeriodPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    dismissOnSelect: true,
  });
  await popover.present();
  return popover
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<{ title: string; value: WidgetCalendarPeriodEnum } | undefined>) => {
      return result;
    });
};

export const calendarHeightPopover = async (ev: Event): Promise<OverlayEventDetail<number | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Calendar/CalendarHeightPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    dismissOnSelect: true,
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<number | undefined>) => {
    return result;
  });
};

export const ideaStatusUpdatePopover = async (
  ev: Event,
  activeStatus: IdeaStatusEnum
): Promise<OverlayEventDetail<IdeaStatusEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Feed/FeedIdeaStatusPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
    reference: 'event',
    componentProps: { activeStatus },
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<IdeaStatusEnum | undefined>) => {
    return result;
  });
};

export const taskManagementColumnPopover = async (
  ev: Event,
  column: TaskManagementColumnModel
): Promise<OverlayEventDetail<TaskManagementColumnActionEnum | undefined>> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Board/TaskManagementColumnPopover.vue')
  );
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    componentProps: { column },
    dismissOnSelect: true,
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<TaskManagementColumnActionEnum | undefined>) => {
    return result;
  });
};

export const componentTagsPickerPopover = async (
  ev: Event,
  selectedTags: TopicModel[],
  multiple: boolean,
  editable: boolean
): Promise<OverlayEventDetail<TopicModel[] | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Topics/TagsPickerPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    dismissOnSelect: false,
    componentProps: { selectedTags, multiple, editable },
    cssClass: ['custom-popover', 'centered-popover', 'stretched-height'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<TopicModel[] | undefined>) => {
    return result;
  });
};

export const componentTaskManagementMilestonesPopover = async (
  ev: Event,
  cardData: TaskManagementTaskModel | null,
  milestoneData: TaskManagementMilestoneModel | null,
  projectId: number,
  onlyCreate: boolean
): Promise<OverlayEventDetail<TaskManagementMilestoneModel | undefined>> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Milestones/TaskManagementMilestonesPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    /* reference: 'trigger', */
    dismissOnSelect: false,
    componentProps: { cardData, milestoneData, projectId, onlyCreate },
    cssClass: ['custom-popover', 'centered-popover', 'stretched-height'],
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<TaskManagementMilestoneModel | undefined>) => {
    return result;
  });
};

export const componentTaskManagementProjectSwitchPopover = async (
  ev: Event,
  isPostCreation: boolean,
  isOnlyProjectCreation: boolean,
  withRouting?: boolean,
  groupId?: number
): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Projects/TaskManagementProjectSwitchPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    /* reference: 'trigger', */
    dismissOnSelect: false,
    componentProps: {
      withRouting,
      groupId,
      isPostCreation,
      isOnlyProjectCreation,
    },
    cssClass: ['custom-popover', 'centered-popover'],
  });
  await popover.present();
};

export const componentTaskManagementViewModePopover = async (ev: Event): Promise<OverlayEventDetail<boolean>> => {
  const component = defineAsyncComponent(() => import('@/components/TaskManagement/TaskManagementViewModePopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    side: 'left',
    dismissOnSelect: true,
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<boolean>) => {
    return { ...result, data: true };
  });
};

export const componentTaskManagementMainHeaderMobilePopover = async (
  ev: Event,
  showProjectControls: boolean,
  showBoardControls: boolean,
  showMilestonesControls: boolean,
  projectId: number
): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/MainHeader/TaskManagementMainHeaderMobilePopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    componentProps: {
      showProjectControls,
      showBoardControls,
      showMilestonesControls,
      projectId,
    },
    dismissOnSelect: true,
  });
  await popover.present();
};

export const componentTaskManagementMainHeaderSearchModePopover = async (ev: Event): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/MainHeader/TaskManagementMainHeaderSearchModePopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    side: 'bottom',
    dismissOnSelect: true,
    showBackdrop: false,
    keyboardClose: false,
  });
  await popover.present();
};

export const componentTaskManagementMainHeaderSortingPopover = async (ev: Event): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/MainHeader/TaskManagementMainHeaderSortingPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    side: 'bottom',
    dismissOnSelect: true,
    showBackdrop: false,
    keyboardClose: false,
  });
  await popover.present();
};

export const componentTaskManagementAttachmentsPopover = async (ev: Event, taskId: number): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Tasks/TaskManagementAttachmentsPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    /* reference: 'trigger', */
    dismissOnSelect: false,
    componentProps: { taskId },
    cssClass: ['custom-popover', 'centered-popover', 'stretched-height'],
  });
  await popover.present();
};

export const componentTaskManagementNewColumnPopover = async (
  ev: Event,
  project: TaskManagementProjectModel
): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Board/TaskManagementNewColumnPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    /* reference: 'trigger', */
    dismissOnSelect: false,
    componentProps: { project },
    cssClass: ['custom-popover', 'centered-popover'],
  });
  await popover.present();
};

export const componentCustomPagesAccessEditPopover = async (
  ev: Event
): Promise<OverlayEventDetail<customPageShowTypeEnum | undefined>> => {
  const component = defineAsyncComponent(
    () => import('@/components/CustomPages/CustomPagesEdit/CustomPageAccessEditPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    dismissOnSelect: true,
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<customPageShowTypeEnum | undefined>) => {
    return result;
  });
};

export const componentFeedViewersPopover = async (postId: number): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Feed/FeedViewersPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    dismissOnSelect: true,
    componentProps: { postId },
    cssClass: ['custom-popover', 'centered-popover'],
  });
  await popover.present();
};

export const componentEventSubscribersPopover = async (postId: number): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Feed/FeedEventSubscribersPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    dismissOnSelect: true,
    componentProps: { postId },
    cssClass: ['custom-popover', 'centered-popover'],
  });
  await popover.present();
};

export const userManagementMenuPopover = async (
  ev: any,
  userId: number
): Promise<OverlayEventDetail<AdminUserManagementMenuEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Admin/AdminUserManagementMenuPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'event',
    dismissOnSelect: true,
    componentProps: { userId },
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<AdminUserManagementMenuEnum | undefined>) => {
    return result;
  });
};

export const wikiTemplatesMenuPopover = async (
  ev: Event | undefined,
  items: TabCategories<WikiEditControlsEnum>[]
): Promise<OverlayEventDetail<WikiEditControlsEnum | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Wikis/WikiTemplatesMenuPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    componentProps: { items },
    side: 'bottom',
    alignment: 'start',
    dismissOnSelect: true,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<WikiEditControlsEnum | undefined>) => {
    return result;
  });
};

export const taskManagementAssignPopover = async (
  ev: Event,
  groupId: number,
  taskData: TaskManagementTaskModel
): Promise<OverlayEventDetail<number | undefined | null>> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Tasks/TaskManagementAssignPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    event: ev,
    mode: 'md',
    componentProps: { groupId, taskData },
    dismissOnSelect: false,
    reference: 'trigger',
    cssClass: ['custom-popover', 'centered-popover', 'stretched-height'],
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<number | undefined | null>) => {
    return result;
  });
};

export const componentCustomPagePublishPopover = async (
  ev: Event,
  editablePage: PageModel | null,
  isGroupDashboard: boolean,
  groupId: number | undefined
): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/CustomPages/CustomPagesEdit/CustomPagePublishPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    event: ev,
    mode: 'md',
    componentProps: { editablePage, isGroupDashboard, groupId },
    dismissOnSelect: true,
    showBackdrop: false,
    reference: 'trigger',
    side: 'bottom',
    alignment: 'end',
    cssClass: [],
  });
  await popover.present();
};

export const componentTaskManagementStatusFilterPopover = async (ev: Event): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/MainHeader/TaskManagementStatusFilterPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    side: 'left',
    dismissOnSelect: true,
    showBackdrop: false,
    keyboardClose: false,
  });
  await popover.present();
};

export const componentDomainSelect = async (
  ev: Event,
  domains: string[]
): Promise<OverlayEventDetail<string | undefined>> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppDomainSelectPopover.vue'));
  const target = ev.currentTarget || ev.target;
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: Object.assign({}, ev, { target }),
    reference: 'trigger',
    componentProps: { domains },
    dismissOnSelect: false,
    alignment: 'start',
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<string | undefined>) => {
    return result;
  });
};

/**
 * --------------------------------------------------------------------------------
 * Deprecated
 * --------------------------------------------------------------------------------
 */

/**
 * @absolute - Use appSortingSelect instead
 * @deprecated - will be removed in the future
 * @see src/helpers/popoverComponents.ts - appSortingSelect
 * @see src/components/Common/AppSortingSelectPopover.vue
 */
export const docBrowserSortingSelect = async (ev: Event): Promise<void> => {
  const DocsSortingSelect = defineAsyncComponent(() => import('@/components/Docs/DocsSortingSelectPopover.vue'));
  const popover = await popoverController.create({
    component: DocsSortingSelect,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
  });
  await popover.present();
};
