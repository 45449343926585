import { alertController, modalController } from '@ionic/vue';
import {
  trashOutline,
  eyeOutline,
  addOutline,
  repeatOutline,
  documentTextOutline,
  saveOutline,
  readerOutline,
  refreshOutline,
} from 'ionicons/icons';
import { cloneDeep, pick } from 'lodash';

import ArchiveLink from '../../assets/icon/archive-link.svg';
import DocVersions from '../../assets/icon/doc-versions.svg';
import Download from '../../assets/icon/download.svg';
import History from '../../assets/icon/history.svg';
import Link from '../../assets/icon/link.svg';
import Move from '../../assets/icon/move.svg';
import NotificationsOff from '../../assets/icon/notifications-off.svg';
import Notifications from '../../assets/icon/notifications.svg';
import PencilSquare from '../../assets/icon/pencil-square.svg';
import Remove from '../../assets/icon/remove.svg';
import Share from '../../assets/icon/share.svg';
import StarO from '../../assets/icon/star-o.svg';
import Star from '../../assets/icon/star.svg';
import Users from '../../assets/icon/users.svg';

import { docBrowserContextMenuSheet } from './actionSheetComponents';
import { isBlob } from './guards';
import { logWarn, logErr } from './logger';
import {
  componentTitleChange,
  componentWikiFollowersModal,
  componentWikiTemplatesModal,
  componentWikiTemplateSaveModal,
  componentShareArchiveLink,
} from './modalComponents';

import {
  DataViewMode,
  DocumentTypeEnum,
  WikiMenuActionEnum,
  WikiEditControlsEnum,
  FileStatusEnum,
  DocumentExtensionEnum,
  WikiDeleteOptionsEnum,
  WikiDownloadOptionsEnum,
  WikiVersionEnum,
  WikiEditOptionsEnum,
  BasicEditControlsEnum,
  WikiSaveModeEnum,
  WikiMajorFilterEnum,
  EditModeEnum,
  AppIconsEnum,
  UserRoleEnum,
  GroupsAccessEnum,
  ActionAccessEnum,
  ShareArchiveLinkType,
  ShareEntityType,
} from '@/enums';
import {
  useFilesHybrid,
  wikiReplaceActionsMenu,
  componentDocsAttachment,
  componentDocsCreateFile,
  componentDocsMoveFile,
  componentWikiCreate,
  componentWikiRelations,
  componentWikiHistoryModal,
  formatDateHelper,
  wikiTemplatesMenuPopover,
  isNativeMobile,
  useToasts,
  canShare,
  canSendArchivedLink,
  shareDoc,
  useErrors,
  docBrowserContextMenu,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { defaultAdvancedWikiParticipantsModel } from '@/models';
import router, { ROUTES_NAME } from '@/router';
import { useWikiStore, useNetworkStore, useAppStore, useDocStore, useUserStore, useGroupsStore } from '@/store';
import type {
  WikiModel,
  WikiHistoryModel,
  MediaModel,
  AppActionButton,
  WikiActionsMenuModel,
  EditOptions,
  EditControls,
  CreateWikiPayload,
  UpdateWikiPayload,
  WikiEditFormModel,
  WhichActionToMakePayload,
  TabCategories,
  WikiTemplateModel,
  UserShortModel,
  CreateWikiTemplateModel,
} from '@/types';

type WikiTemplatesTableHeader = {
  field: keyof WikiTemplateModel | null;
  editable: boolean;
  headerName: string;
  cellStyle: () => any;
  flex?: number;
  valueFormatter?: (params: { value: any }) => string;
  cellRenderer?: (params: { value: string; data: WikiTemplateModel }) => any;
  width?: number;
}[];

export type HistoricalButtons = {
  left: AppActionButton;
  right: AppActionButton;
};

export type IUseWiki = {
  //#region Getters
  /** Use to get menu items for wiki actions */
  getActionsMenuItems: (wiki: WikiModel) => WikiActionsMenuModel[];
  /** Use to get menu items for templates */
  getTemplatesMenuItems: () => TabCategories<WikiEditControlsEnum>[];
  /** Use to get edit options based on the edit mode */
  getEditOptions: (mode: EditModeEnum) => EditOptions[];
  /** Use to get edit controls based on the edit mode */
  getEditControls: (mode: EditModeEnum) => EditControls[];
  /** Use to get the table headers for templates */
  getTemplatesTableHeader: () => any;
  /**
   * Use to check if the user is allowed to create a wiki in the given group or in the general feed.
   * If `groupId` is NOT null, checks access for the group.
   * If `groupId` is null, checks access for the general feed.
   */
  getCreateAccess: (groupId: number | null) => boolean;
  /** Use to get historical buttons for a wiki version */
  getHistoricalButtons: (version: WikiHistoryModel, mode: DataViewMode) => HistoricalButtons;
  /** Use to get the avatar of the user who modified the wiki */
  getHistoricalModifiedByAvatar: (wiki: WikiHistoryModel) => MediaModel | null;
  //#endregion

  //#region Actions
  /** Use to create a new wiki */
  create: () => Promise<void>;
  /** Use to open a wiki by its ID */
  open: (id: number, versionId?: number) => Promise<void>;
  /** Use to show the history of a wiki */
  showHistory: (id: number, fromDocBrowser?: boolean, fromComparePage?: boolean) => Promise<number | undefined>;
  /** Use to compare historical versions of a wiki */
  compareHistorical: (sourceId?: number, ids?: number[], fromComparePage?: boolean) => Promise<void>;
  /** Use to delete a wiki */
  deleteWiki: (wiki?: WikiModel, id?: number) => Promise<void>;
  //#endregion

  //#region Handlers
  /** Use to handle a specific action for a wiki */
  handleAction: (payload: WhichActionToMakePayload, fromDocBrowser?: boolean) => Promise<void>;
  /** Use to handle the selected edit option */
  handleEditOption: (action: WikiEditOptionsEnum) => Promise<void>;
  /** Use to handle the selected edit control */
  handleEditControl(
    action: BasicEditControlsEnum | WikiEditControlsEnum,
    ev?: Event,
    isModal?: boolean
  ): Promise<boolean | number | null | void>;
  //#endregion

  //#region Miscellaneous
  /** Use to check if the user is not allowed to edit a wiki with the given ID */
  preventEdit: (id: number) => Promise<boolean>;
  /** Use to open the actions menu for a wiki */
  openActionsMenu: (event: Event, wiki: WikiModel) => Promise<void>;
  /** Use to navigate to a historical version of a wiki */
  goToHistorical: (id: number, date: string, routeName: string) => Promise<void>;
  //#endregion
};

export const useWiki = (): IUseWiki => {
  //#region Icons
  const icons = {
    eye: eyeOutline,
    plus: addOutline,
    arrows: repeatOutline,
    save: saveOutline,
    doc: documentTextOutline,
    trash: trashOutline,
    templates: readerOutline,
    refresh: refreshOutline,
  };
  //#endregion

  //#region Helpers
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();
  //#endregion

  //#region Private methods
  /** @private */
  async function _confirmDelete(wikiId: number | undefined, versionId: number | undefined): Promise<void> {
    const id = wikiId || versionId;

    if (!id) return logWarn('Wiki id is not defined');

    const alert = await alertController.create({
      message: `${t('documents.popup.deleteWiki')} <strong>${id}</strong>?`,
      buttons: [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('yes'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            try {
              const result = await useWikiStore().delete(id);
              if (!result) return;

              showSonnerToast(t('documents.popup.deleteSuccess'), true);
              await alertController.dismiss();
              router.currentRoute.value.name === ROUTES_NAME.WIKI_BY_ID && router.back();
            } catch (e) {
              logErr('Failed to confirm wiki deletion', e);
              showSonnerToast(t('documents.popup.deleteError'), false);
            }
          },
        },
      ],
    });

    await alert.present();
  }

  /** @private */
  async function _replaceFromDocBrowser(wiki: WikiModel): Promise<boolean> {
    try {
      const result = await componentDocsAttachment(null);

      if (result.data) {
        const data = result.data;

        data.forEach(async (element) => {
          if (element.documentType === DocumentTypeEnum.Wiki) {
            await useWikiStore().deleteWithReplace(wiki.id, element.data.id, null);
          } else {
            await useWikiStore().deleteWithReplace(wiki.id, null, element.data.id);
          }
        });
      }

      return false;
    } catch (e) {
      return false;
    }
  }

  /** @private */
  async function _replaceWithNewWiki(wiki: WikiModel): Promise<boolean> {
    const result = await componentWikiCreate(wiki.group?.id, wiki?.parentFolderId ?? undefined, false);

    if (result) {
      await useWikiStore().deleteWithReplace(wiki.id, result, null);
    }

    return false;
  }

  /** @private */
  async function _replaceWithNewFile(wiki: WikiModel): Promise<boolean> {
    const result = await componentDocsCreateFile();

    if (result !== null) {
      await useWikiStore().deleteWithReplace(wiki.id, null, result.id);
    }

    return false;
  }

  /** @private */
  async function _replace(wiki: WikiModel): Promise<boolean> {
    const result = await wikiReplaceActionsMenu();

    if (!result.data) return false;

    if (result.data === WikiDeleteOptionsEnum.ReplaceFromDocBrowser) {
      await _replaceFromDocBrowser(wiki);
      return true;
    }

    if (result.data === WikiDeleteOptionsEnum.ReplaceWithNewWiki) {
      await _replaceWithNewWiki(wiki);
      return true;
    }

    if (result.data === WikiDeleteOptionsEnum.ReplaceWithNewFile) {
      await _replaceWithNewFile(wiki);
      return true;
    }

    return false;
  }

  /** @private */
  async function _deleteTemplate(template: WikiTemplateModel): Promise<void> {
    const deleteTemplate = async () => {
      const result = await useWikiStore().deleteTemplateById(template.id);
      if (result) {
        showSonnerToast(t('wiki.templates.delete.success'), true);
      } else {
        showSonnerToast(t('wiki.templates.delete.error'), false);
      }
    };

    const alert = await alertController.create({
      header: t('wiki.templates.delete.title'),
      message: t('wiki.templates.delete.confirm', { name: template.name }),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            await deleteTemplate();
            await alertController.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  /** @private */
  async function _updateExistingWiki(payload: UpdateWikiPayload, isModal: boolean): Promise<number | null> {
    const result = await useWikiStore().update({
      wikiId: useWikiStore().existingWiki?.id || 0,
      mentionedUserIds: useWikiStore().editForm.mentionedUserIds ?? [],
      ...payload,
    });

    if (!result) {
      showSonnerToast(t('wiki.editMessages.updateError'), false);
      return null;
    }

    showSonnerToast(t('wiki.editMessages.updatedSuccess'), true);

    await _leaveEditPage(isModal, payload.wikiId!);
    return payload.wikiId!;
  }

  /** @private */
  async function _onNewWikiCreate(payload: CreateWikiPayload, isModal: boolean): Promise<number | null> {
    const createdId = await useWikiStore().create(payload);

    if (!createdId) {
      logWarn('Error during creating wiki');
      showSonnerToast(t('wiki.editMessages.createError'), false);
      return null;
    }

    showSonnerToast(t('wiki.editMessages.createSuccess'), true);
    await _leaveEditPage(isModal, createdId);
    return createdId;
  }
  //#endregion

  //#region Getters
  function getActionsMenuItems(wiki: WikiModel): WikiActionsMenuModel[] {
    const menuItems = [
      {
        title: t(`wiki.menuActions.edit`),
        value: WikiMenuActionEnum.Edit,
        icon: AppIconsEnum.PencilSquare,
        svgIconPath: PencilSquare,
        disabled: !wiki.access.includes(ActionAccessEnum.Edit),
      },
      {
        title: t(`files.menu.downloadAsPDF`),
        value: WikiDownloadOptionsEnum.DownloadAsPDF,
        icon: AppIconsEnum.Download,
        svgIconPath: Download,
        disabled: false,
      },
      {
        title: t(`files.menu.downloadAsDOCX`),
        value: WikiDownloadOptionsEnum.DownloadAsDOCX,
        icon: AppIconsEnum.Download,
        svgIconPath: Download,
        disabled: false,
      },
      {
        title: t('files.menu.share'),
        icon: AppIconsEnum.Share,
        svgIconPath: Share,
        disabled: !(wiki.access.includes(ActionAccessEnum.Share) && canShare()),
        value: WikiMenuActionEnum.Share,
      },
      {
        title: t('feed.conversationPostMenu.send.shareArchiveLink'),
        icon: AppIconsEnum.ArchiveLink,
        svgIconPath: ArchiveLink,
        disabled: !(wiki.access.includes(ActionAccessEnum.Share) && canShare() && canSendArchivedLink()),
        value: WikiMenuActionEnum.ShareArchiveLink,
      },
      {
        title: wiki.isOfficial ? t(`wiki.menuActions.unmarkOfficial`) : t(`wiki.menuActions.markOfficial`),
        value: WikiMenuActionEnum.MarkOfficial,
        icon: wiki.isOfficial ? AppIconsEnum.Star : AppIconsEnum.StarOutline,
        svgIconPath: wiki.isOfficial ? Star : StarO,
        disabled: !wiki.access.includes(ActionAccessEnum.MarkAsOfficial),
      },
      {
        title: t(`wiki.menuActions.showRelations`),
        value: WikiMenuActionEnum.ShowRelations,
        icon: AppIconsEnum.Link,
        svgIconPath: Link,
        disabled: true,
      },
      {
        title: wiki.isFollowed ? t(`subscribe.unfollow`) : t(`subscribe.follow`),
        value: wiki.isFollowed ? WikiMenuActionEnum.Unfollow : WikiMenuActionEnum.Follow,
        icon: wiki.isFollowed ? AppIconsEnum.NotificationsOff : AppIconsEnum.Notifications,
        svgIconPath: wiki.isFollowed ? NotificationsOff : Notifications,
        disabled: false,
      },
      {
        title: t(`wiki.menuActions.showFollowers`),
        value: WikiMenuActionEnum.ShowFollowers,
        icon: AppIconsEnum.Users,
        svgIconPath: Users,
        disabled: !useNetworkStore().showFollowLists,
      },
      {
        title: t(`wiki.menuActions.move.title`),
        value: WikiMenuActionEnum.Move,
        icon: AppIconsEnum.Move,
        svgIconPath: Move,
        disabled: !wiki.access.includes(ActionAccessEnum.Move),
      },
      {
        title: t(`wiki.menuActions.showHistory`),
        value: WikiMenuActionEnum.ShowHistory,
        icon: AppIconsEnum.DocVersions,
        svgIconPath: DocVersions,
        disabled: !wiki.access.includes(ActionAccessEnum.Edit),
      },
      {
        title: t(`wiki.menuActions.rollback.title`),
        value: WikiMenuActionEnum.Rollback,
        icon: AppIconsEnum.History,
        svgIconPath: History,
        disabled:
          !wiki.access.includes(ActionAccessEnum.Edit) ||
          (!useWikiStore().historicalWiki && !useWikiStore().isOutdated),
      },
      {
        title: t(`wiki.menuActions.delete`),
        value: WikiMenuActionEnum.Delete,
        icon: AppIconsEnum.Remove,
        svgIconPath: Remove,
        disabled: !wiki.access.includes(ActionAccessEnum.Delete),
      },
    ];
    return menuItems.filter(({ disabled }) => !disabled);
  }

  function getTemplatesMenuItems(): TabCategories<WikiEditControlsEnum>[] {
    const currentUserRoleId = useUserStore().current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    return [
      {
        value: WikiEditControlsEnum.ChooseTemplate,
        active: true,
        icon: icons.templates,
        title: t('wiki.templates.choose.title'),
      },
      {
        value: WikiEditControlsEnum.SaveAsTemplate,
        active: currentUserRoleId >= UserRoleEnum.Administrator,
        icon: icons.save,
        title: t('wiki.templates.save.title'),
      },
      {
        value: WikiEditControlsEnum.UpdateTemplate,
        active: currentUserRoleId >= UserRoleEnum.Administrator,
        icon: icons.refresh,
        title: t('wiki.templates.update.title'),
      },
    ].filter((item) => item.active);
  }

  function getEditOptions(mode: EditModeEnum): EditOptions[] {
    const options = [
      {
        title: t('wiki.editOptions.title'),
        action: WikiEditOptionsEnum.EditTitle,
        value: useWikiStore().editForm.name || t('titleChangeModal.placeholder.title'),
      },
      {
        title: t('wiki.editOptions.group'),
        action: WikiEditOptionsEnum.None,
        value: useWikiStore().getGroupTitle,
      },
      {
        title: t('wiki.editOptions.folder'),
        action: WikiEditOptionsEnum.None,
        value: useWikiStore().getFolderTitle,
      },
      {
        title: t('wiki.editOptions.author'),
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? (useWikiStore().getCreatedBy?.fullName ?? '') : '',
      },
      {
        title: t('wiki.editOptions.createdAt'),
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? formatDateHelper(useWikiStore().getCreationDate, 'long') : '',
      },
      {
        title: t('wiki.editOptions.lastEditedBy'),
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? (useWikiStore().getLastEditor?.fullName ?? '') : '',
      },
      {
        title: t('wiki.editOptions.lastEditedAt'),
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? formatDateHelper(useWikiStore().getLastEditDate, 'long') : '',
      },
      {
        title: t('wiki.editOptions.outdated'),
        action: WikiEditOptionsEnum.Outdated,
        value: mode === EditModeEnum.Edit ? (useWikiStore().isOutdated ? t('wiki.editOptions.outdated') : '') : '',
      },
    ];

    return options.filter(({ action, value }) => action === WikiEditOptionsEnum.EditTitle || value !== '');
  }

  function getEditControls(mode: EditModeEnum): EditControls[] {
    const controls = [
      {
        title: t('wiki.editControls.goToCurrentVersion'),
        icon: icons.eye,
        disabled: true,
        value: WikiEditControlsEnum.GoToCurrentVersion,
        isDropdown: false,
      },
      {
        title: t('wiki.editControls.deleteNote'),
        icon: icons.trash,
        disabled: mode !== EditModeEnum.Edit && !useWikiStore().existingWiki?.id,
        value: WikiEditControlsEnum.Delete,
        isDropdown: false,
      },
      {
        title: t('wiki.templates.title'),
        icon: icons.templates,
        disabled: !useNetworkStore().isAdvancedWikiesEditor,
        value: WikiEditControlsEnum.TemplatesMenu,
        isDropdown: true,
      },
    ];

    return controls.filter(({ disabled }) => !disabled);
  }

  function getTemplatesTableHeader(): WikiTemplatesTableHeader {
    const isSMWidth = useAppStore().isSMWidth;
    const currentUserRoleId = useUserStore().current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    const _cellStyle = {
      'line-height': 1,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    };

    const _nameRenderer = (params: { value: string; data: WikiTemplateModel }) => {
      return `<span style="display:block; font-size: 0.9rem; padding-bottom: 0.4rem; font-weight: 500;">${params.value}</span><span style="display:block;padding-bottom: 0.2rem;font-size: 0.8rem">${params.data.createdBy.fullName}</span><span style="font-size: 0.8rem"> ${formatDateHelper(params.data.createdAt, 'short')}</span>`;
    };

    const _deleteRenderer = (params: { value: string; data: WikiTemplateModel }) => {
      const removeIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="100%" height="100%"><path stroke-width="1" fill="var(--ion-color-dark)" fill-rule="evenodd" clip-rule="evenodd" d="M5.75 3V1.5h4.5V3h-4.5zm-1.5 0V1a1 1 0 0 1 1-1h5.5a1 1 0 0 1 1 1v2h2.5a.75.75 0 0 1 0 1.5h-.365l-.743 9.653A2 2 0 0 1 11.148 16H4.852a2 2 0 0 1-1.994-1.847L2.115 4.5H1.75a.75.75 0 0 1 0-1.5h2.5zm-.63 1.5h8.76l-.734 9.538a.5.5 0 0 1-.498.462H4.852a.5.5 0 0 1-.498-.462L3.62 4.5z"/></svg>`;

      const button = document.createElement('button');
      button.className = 'table-action-button';
      button.innerHTML = removeIcon;
      button.onclick = (event: Event) => {
        event.stopPropagation();
        _deleteTemplate(params.data);
      };
      return button;
    };

    const _deleteColumn = {
      field: null,
      width: 64,
      editable: false,
      headerName: '',
      cellRenderer: (params: { value: string; data: WikiTemplateModel }) => _deleteRenderer(params),
      cellStyle: () => ({ ..._cellStyle, justifyContent: 'end' }),
    };

    const _mobileHeader = [
      {
        field: 'name' as keyof WikiTemplateModel,
        flex: 2,
        editable: false,
        headerName: t('wiki.templates.templateName'),
        cellStyle: () => _cellStyle,
        cellRenderer: (params: { value: string; data: WikiTemplateModel }) => _nameRenderer(params),
      },
      ...(currentUserRoleId >= UserRoleEnum.Administrator ? [_deleteColumn] : []),
    ];

    const _desktopHeader = [
      {
        field: 'name' as keyof WikiTemplateModel,
        flex: 2,
        editable: false,
        headerName: t('wiki.templates.templateName'),
        cellStyle: () => _cellStyle,
      },
      {
        field: 'createdBy' as keyof WikiTemplateModel,
        flex: 1,
        editable: false,
        headerName: t('wiki.table.author'),
        valueFormatter: (params: { value: UserShortModel }) => params.value.fullName,
        cellStyle: () => _cellStyle,
      },
      {
        field: 'createdAt' as keyof WikiTemplateModel,
        flex: 1,
        editable: false,
        headerName: t('wiki.table.date'),
        valueFormatter: (params: { value: string }) => formatDateHelper(params.value, 'short'),
        cellStyle: () => _cellStyle,
      },
      ...(currentUserRoleId >= UserRoleEnum.Administrator ? [_deleteColumn] : []),
    ];

    return isSMWidth ? _desktopHeader : _mobileHeader;
  }

  function getCreateAccess(groupId: number | null): boolean {
    const allowPostToFeed = useNetworkStore().settings?.allowPostToFeed ?? false;
    const currentUserRoleId = useUserStore().current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    return groupId
      ? useGroupsStore().getGroupById(groupId).accessType >= GroupsAccessEnum.Member
      : allowPostToFeed && currentUserRoleId >= UserRoleEnum.User;
  }

  function getHistoricalButtons(version: WikiHistoryModel, mode: DataViewMode): HistoricalButtons {
    return {
      left: {
        title: t('wiki.menuActions.rollback.title'),
        action: WikiMenuActionEnum.Rollback,
        type: 'main',
        icon: AppIconsEnum.History,
        showIcon: mode === DataViewMode.List,
        showTooltip: mode === DataViewMode.List,
        showTitle: false,
      },
      right: {
        title: t('wiki.menuActions.compare'),
        action: WikiMenuActionEnum.CompareHistorical,
        type: 'main',
        icon: AppIconsEnum.Comparison,
        showIcon: mode === DataViewMode.List,
        showTooltip: mode === DataViewMode.List,
        showTitle: false,
      },
    };
  }

  function getHistoricalModifiedByAvatar(wiki: WikiHistoryModel): MediaModel | null {
    if (wiki.modifyUserId) {
      const uId = wiki.modifyUserId;
      const user = useUserStore().getUserProfile(uId);
      return user?.avatar;
    }
    return null;
  }

  /** @private */
  function _getPreparedWikiTemplateData(editForm: WikiEditFormModel): CreateWikiTemplateModel | null {
    const isAdvancedWikiesEditor = useNetworkStore().isAdvancedWikiesEditor;
    if (isAdvancedWikiesEditor && editForm.wikiContent) {
      return {
        name: '',
        content: pick(editForm.wikiContent, ['body', 'head', 'content']),
      };
    } else if (editForm.wikiText) {
      logWarn('Simple-wiki templates is not supported');
      return null;
    } else {
      logWarn('No data to create template');
      return null;
    }
  }
  //#endregion

  //#region Actions
  async function create(): Promise<void> {
    try {
      await router.push({
        name: ROUTES_NAME.WIKI_CREATE,
      });
    } catch (e) {
      logErr('Failed to go to wiki edit page', e);
    }
  }

  async function open(id: number, versionId?: number): Promise<void> {
    await router.push({
      name: ROUTES_NAME.WIKI_BY_ID,
      params: { id: id, versionId: versionId },
    });
  }

  /** @private */
  async function _edit(wiki: WikiModel | undefined): Promise<void> {
    if (!wiki) return logWarn('Wiki is not defined');
    useWikiStore().setPartialEditForm({
      groupId: wiki.group?.id ?? null,
      folderId: wiki.parentFolderId ?? null,
    });

    await router.push({
      name: ROUTES_NAME.WIKI_EDIT,
      params: { id: wiki.id },
    });
  }

  /** @private */
  async function _move(id: number): Promise<void> {
    try {
      const result = await componentDocsMoveFile(null);
      if (!result.data) return logWarn('No folder selected');

      await useDocStore().moveWiki(result.data.folderId, result.data.groupId, id);

      showSonnerToast(t('wiki.menuActions.move.success'), true);
      await useWikiStore().getWikiById(id);
    } catch (e) {
      logErr('Failed to move wiki', e);
      showSonnerToast(t('wiki.menuActions.move.error'), false);
    }
  }

  /** @private */
  async function _markOfficial(id: number): Promise<void> {
    try {
      const topModal = await modalController.getTop();
      if (topModal?.id === 'wiki_actions') {
        await modalController.dismiss(null, 'end', 'wiki_actions');
      }
    } catch (e) {
      logErr('Error dismissing modal:', e);
    }

    await useWikiStore().markOfficial(id);
  }

  /** @private */
  async function _showRelations(id: number): Promise<void> {
    await useWikiStore().getRelations(id);
    await componentWikiRelations();
  }

  /** @private */
  async function _showFollowers(id: number): Promise<void> {
    await useWikiStore().getFollowers(id);
    await componentWikiFollowersModal();
  }

  async function showHistory(
    id: number,
    fromDocBrowser?: boolean,
    fromComparePage?: boolean
  ): Promise<number | undefined> {
    const existingWiki = useWikiStore().existingWiki;

    if (!existingWiki || existingWiki.id !== id) {
      await useWikiStore().getWikiById(id);
    }

    if (!useWikiStore().history || !fromComparePage) {
      const result = await useWikiStore().getHistory(id);
      if (!result) {
        logWarn('Failed to get history');
        showSonnerToast('Failed to get updated history. Please refresh the page.', false);
        return;
      }
    }

    if (!existingWiki) {
      const latest = await useWikiStore().getHistoricalWikiByDate(
        id,
        new Date().toISOString(),
        WikiMajorFilterEnum.All
      );
      if (!latest) {
        logWarn('Failed to get latest historical wiki');
        return;
      }
    }

    return await componentWikiHistoryModal(fromDocBrowser, fromComparePage);
  }

  /** @private */
  async function _rollback(id: number | undefined, versionId: number | undefined): Promise<void> {
    if (!id || !versionId) return logWarn('Failed to rollback wiki: id or versionId is not defined');

    try {
      await useWikiStore().rollback(id, versionId);
      showSonnerToast(t('wiki.menuActions.rollback.success'), true);
    } catch (e) {
      logErr('Failed to rollback wiki', e);
      showSonnerToast(t('wiki.menuActions.rollback.error'), false);
    }
  }

  async function compareHistorical(sourceId?: number, ids?: number[], fromComparePage = false): Promise<void> {
    if (!sourceId && !ids) {
      logWarn('Failed to compare historical: sourceId or ids are not defined');
      return;
    }

    let tId = 0;
    let sId = 0;

    if (ids) {
      tId = ids[0];
      sId = ids[1];
    } else if (sourceId) {
      tId = useWikiStore().history[0].id;
      sId = sourceId;
    }

    useWikiStore().setOnCompare(
      useWikiStore().getHistoryWikiById(tId),
      useWikiStore().getHistoryWikiById(sId),
      useWikiStore().existingWiki?.name ?? ''
    );

    if (!tId || !sId) {
      logWarn('Failed to compare historical: tId or sId is not defined');
      return;
    }

    if (!fromComparePage) {
      await router.push({
        name: ROUTES_NAME.WIKI_COMPARE,
        params: { id: useWikiStore().existingWiki?.id },
        query: {
          targetId: tId,
          sourceId: sId,
        },
      });
    }
  }

  async function deleteWiki(wiki?: WikiModel, id?: number): Promise<void> {
    if (!wiki?.id && !id) {
      logWarn('Wiki id is not defined');
      return;
    }

    try {
      if (!wiki?.id && id) {
        await _confirmDelete(undefined, id);
      } else if (wiki?.id) {
        const optionsAlert = await alertController.create({
          message: `${t('documents.popup.deleteWiki')} <strong>${wiki?.name ?? id}</strong>?`,
          buttons: [
            {
              text: t('documents.popup.deleteWithAllRelations'),
              cssClass: 'custom-alert-buttons',
              handler: async () => {
                await _confirmDelete(wiki.id, undefined);
              },
            },
            {
              text: t('documents.popup.deleteWithReplace'),
              cssClass: 'custom-alert-buttons',
              handler: async () => {
                try {
                  await _replace(wiki);
                  showSonnerToast(t('documents.popup.deleteSuccess'), true);
                  await alertController.dismiss();
                } catch (e) {
                  logErr('Failed to delete wiki', e);
                  showSonnerToast(t('documents.popup.deleteError'), false);
                }
              },
            },
          ],
        });

        await optionsAlert.present();
      }
    } catch (e) {
      logErr('Failed to delete wiki', e);
    }
  }

  /** @private */
  async function _follow(id: number): Promise<void> {
    await useWikiStore().follow(id);
  }

  /** @private */
  async function _unfollow(id: number): Promise<void> {
    await useWikiStore().unfollow(id);
  }

  /** @private */
  async function _download(wiki: WikiModel | undefined, documentExtension: DocumentExtensionEnum): Promise<void> {
    if (!wiki) return logWarn('Wiki is not defined');

    const response = await useWikiStore().download(wiki.id, documentExtension);

    if (isBlob(response)) {
      const result = await useFilesHybrid().downloadWiki(wiki, response);
      showSonnerToast(t('files.successDownloaded'), result === FileStatusEnum.Success);
    } else {
      logWarn(`Response is not a Blob: ${JSON.stringify(response)}`);
    }
  }
  //#endregion

  //#region Edit Options
  /** @private */
  async function _editTitle(): Promise<void> {
    const existingWiki = useWikiStore().existingWiki;
    const editForm = useWikiStore().editForm;

    const result = await componentTitleChange(null, existingWiki?.name ?? editForm.name ?? '');
    if (result.data?.title) {
      useWikiStore().setHasChanged(true);
      useWikiStore().setSimpleWiki('name', result.data?.title);
    }
  }

  /** @private */
  function _showAuthor() {
    const author = useWikiStore().getCreatedBy;
    console.log('author:', author); //! DEBUG
  }

  /** @private */
  function _showLastEditor() {
    const lastEditor = useWikiStore().getLastEditor;
    console.log('lastEditor:', lastEditor); //! DEBUG
  }

  /** @private */
  function _showGroup() {
    const group = useWikiStore().getGroupTitle;
    console.log('group:', group); //! DEBUG
  }

  /** @private */
  function _showFolder() {
    const folder = useWikiStore().getFolderTitle;
    console.log('folder:', folder); //! DEBUG
  }

  /** @private */
  function _showOutdated() {
    const outdated = useWikiStore().isOutdated;
    console.log('outdated:', outdated); //! DEBUG
  }
  //#endregion

  //#region Edit Controls
  /** @private */
  async function _leaveEditPage(isModal: boolean, id?: number): Promise<void> {
    try {
      if (isModal) {
        await modalController.dismiss(id);
        return;
      }

      if (!id) {
        router.back();
        return;
      }

      await router.replace({
        name: ROUTES_NAME.WIKI_BY_ID,
        params: { id },
      });
    } catch (e) {
      logErr('Failed to leave edit page:', e);
    }
  }

  /** @private */
  async function _save(saveOption: WikiSaveModeEnum, isModal: boolean): Promise<number | null> {
    if (saveOption === WikiSaveModeEnum.Minor) {
      useWikiStore().setSaveMode(saveOption);
    }

    useWikiStore().setSaveLoading(true);

    try {
      const check = useWikiStore().checkFields();
      if (!check.ok) {
        showSonnerToast(check.reason, false, undefined, undefined, undefined, undefined, undefined, undefined, true);
        return null;
      }

      const editForm = useWikiStore().editForm;
      const advancedContent = {
        head: editForm.wikiContent.head,
        content: editForm.wikiContent.content,
        body: editForm.wikiContent.body,
        participants: {
          name: editForm.wikiContent.participants.name,
          participantsIds: editForm.participantsIds,
          settings: {
            allUsers: false,
            isVisible: true,
            isDeleted: false,
          },
        },
      };

      const isAdvancedWikiesEditor = useNetworkStore().isAdvancedWikiesEditor;
      const selectedGroup = useDocStore().selectedGroup;
      const selectedFolder = useDocStore().selectedFolder;
      const saveMode = useWikiStore().currentSaveMode;
      const payload: CreateWikiPayload = {
        name: editForm.name,
        groupId: selectedGroup?.id || editForm.groupId,
        folderId: false ? selectedFolder?.id || null : selectedFolder?.id || editForm.folderId,
        isMajor: saveMode !== WikiSaveModeEnum.Minor,
        text: !isAdvancedWikiesEditor ? editForm.wikiText : null,
        content: isAdvancedWikiesEditor ? advancedContent : null,
        participantsIds: editForm.participantsIds,
        accessOnlyForGroupId: null,
        mentionedUserIds: useWikiStore().editForm.mentionedUserIds ?? [],
      };

      if (useWikiStore().existingWiki) {
        return await _updateExistingWiki(payload, isModal);
      }

      return await _onNewWikiCreate(payload, isModal);
    } catch (e) {
      logErr('Failed to save wiki', e);
      return null;
    } finally {
      useWikiStore().setSaveLoading(false);
    }
  }

  /** @private */
  async function _goToCurrentVersion() {
    useWikiStore().resetFromHistorical();
  }

  /** @private */
  async function _saveAsTemplate() {
    const templateData = _getPreparedWikiTemplateData(useWikiStore().editForm);
    if (!templateData) return;

    const result = await componentWikiTemplateSaveModal(templateData);

    if (result.data) {
      showSonnerToast(t('wiki.templates.save.success'), true);
    } else if (result.data === false) {
      showSonnerToast(t('wiki.templates.save.error'), false);
    }
  }

  /** @private */
  async function _updateTemplate() {
    let selectedTemplate: WikiTemplateModel | undefined;
    const templateData = _getPreparedWikiTemplateData(useWikiStore().editForm);
    if (!templateData) return;

    const updateTemplate = async () => {
      if (selectedTemplate && templateData) {
        const result = await useWikiStore().updateTemplateById({
          ...selectedTemplate,
          ...templateData,
        });
        if (result) {
          showSonnerToast(t('wiki.templates.update.success'), true);
        } else {
          showSonnerToast(t('wiki.templates.update.error'), false);
        }
      } else {
        logWarn('Error while updating template');
      }
    };

    const alert = await alertController.create({
      header: t('wiki.templates.update.title'),
      message: t('wiki.templates.update.confirm'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: () => {
            updateTemplate();
          },
        },
      ],
    });

    const result = await componentWikiTemplatesModal(t('wiki.templates.update.title'));
    if (result.data) {
      selectedTemplate = result.data;
      await alert.present();
    } else {
      selectedTemplate = undefined;
      return;
    }
  }

  /** @private */
  async function _chooseTemplate() {
    let selectedTemplate: WikiTemplateModel | undefined;

    const alert = await alertController.create({
      header: t('wiki.templates.choose.title'),
      message: t('wiki.templates.choose.confirm'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            if (!selectedTemplate) {
              logWarn('Selected template is not defined');
              return;
            }

            selectedTemplate = await useWikiStore().templateById(selectedTemplate.id);
            if (selectedTemplate?.wikiContent) {
              useWikiStore().setAdvancedWikiContent({
                ...selectedTemplate.wikiContent,
                participants: cloneDeep(defaultAdvancedWikiParticipantsModel),
              });
              showSonnerToast(t('wiki.templates.choose.success'), true);
            } else {
              logWarn('Selected template content is not defined');
              showSonnerToast(t('wiki.templates.choose.error'), false);
            }
          },
        },
      ],
    });

    const result = await componentWikiTemplatesModal(t('wiki.templates.choose.title'));
    selectedTemplate = result.data;
    selectedTemplate && (await alert.present());
  }

  /** @private */
  async function _handleTemplatesMenu(ev: Event | undefined) {
    const items = getTemplatesMenuItems();
    const result = items.length > 1 ? await wikiTemplatesMenuPopover(ev, items) : { data: items[0].value };
    if (!result.data) return;

    if (result.data === WikiEditControlsEnum.SaveAsTemplate) return await _saveAsTemplate();

    if (result.data === WikiEditControlsEnum.ChooseTemplate) return await _chooseTemplate();

    if (result.data === WikiEditControlsEnum.UpdateTemplate) return await _updateTemplate();
  }
  //#endregion

  //#region Handlers
  async function handleAction(payload: WhichActionToMakePayload, fromDocBrowser?: boolean): Promise<void> {
    const finalId = payload.id || payload.wiki?.id || payload.versionId;
    if (!finalId) {
      logWarn('Action cannot be performed: finalId resolved to null');
      return;
    }

    const actionsMap: Record<WikiMenuActionEnum | WikiDownloadOptionsEnum, () => Promise<void | number>> =
      Object.freeze({
        [WikiMenuActionEnum.Create]: create,
        [WikiMenuActionEnum.Open]: async () => open(finalId),
        [WikiMenuActionEnum.Edit]: async () => _edit(payload.wiki),
        [WikiMenuActionEnum.Move]: async () => _move(finalId),
        [WikiMenuActionEnum.MarkOfficial]: async () => _markOfficial(finalId),
        [WikiMenuActionEnum.ShowRelations]: async () => _showRelations(finalId),
        [WikiMenuActionEnum.ShowFollowers]: async () => _showFollowers(finalId),
        [WikiMenuActionEnum.ShowHistory]: async () => showHistory(finalId, fromDocBrowser, false),
        [WikiMenuActionEnum.Rollback]: async () => _rollback(payload.wiki?.id, payload.versionId),
        [WikiMenuActionEnum.CompareHistorical]: async () => compareHistorical(finalId),
        [WikiMenuActionEnum.Delete]: async () => deleteWiki(payload.wiki, finalId),
        [WikiMenuActionEnum.Follow]: async () => _follow(finalId),
        [WikiMenuActionEnum.Unfollow]: async () => _unfollow(finalId),
        [WikiMenuActionEnum.ShareArchiveLink]: async () =>
          componentShareArchiveLink(finalId, ShareArchiveLinkType.Wiki),
        [WikiMenuActionEnum.Share]: async () => shareDoc(payload.wiki, ShareEntityType.Wiki),
        [WikiDownloadOptionsEnum.DownloadAsPDF]: async () => _download(payload.wiki, DocumentExtensionEnum.PDF),
        [WikiDownloadOptionsEnum.DownloadAsDOCX]: async () => _download(payload.wiki, DocumentExtensionEnum.DOCX),
      });

    const handler = actionsMap[payload.action as WikiMenuActionEnum | WikiDownloadOptionsEnum];
    if (!handler) {
      console.warn('No handler found for action: ', payload.action);
      return;
    }

    try {
      await handler();
    } catch (error) {
      logErr(`Error executing action ${payload.action}:`, error);
      showSonnerToast(t('errorResponse'), false);
    }
  }

  async function handleEditOption(action: WikiEditOptionsEnum): Promise<void> {
    const optionsMap: Record<WikiEditOptionsEnum, () => Promise<void> | void> = Object.freeze({
      [WikiEditOptionsEnum.None]: () => logWarn('No handler for editing options'),
      [WikiEditOptionsEnum.EditTitle]: _editTitle,
      [WikiEditOptionsEnum.ShowAuthor]: _showAuthor,
      [WikiEditOptionsEnum.ShowLastEditor]: _showLastEditor,
      [WikiEditOptionsEnum.EditGroup]: _showGroup,
      [WikiEditOptionsEnum.EditFolder]: _showFolder,
      [WikiEditOptionsEnum.Outdated]: _showOutdated,
    });

    const handler = optionsMap[action];
    if (!handler) {
      console.warn('No handler found for edit option: ', action);
      return;
    }

    try {
      await handler();
    } catch (error) {
      logErr(`Error executing edit option ${action}:`, error);
      showSonnerToast(t('errorResponse'), false);
    }
  }

  async function handleEditControl(
    action: BasicEditControlsEnum | WikiEditControlsEnum,
    ev?: Event,
    isModal = false
  ): Promise<boolean | number | null | void> {
    const controlsMap: Record<BasicEditControlsEnum | WikiEditControlsEnum, () => Promise<void | number | null>> =
      Object.freeze({
        [BasicEditControlsEnum.Cancel]: async () => await _leaveEditPage(isModal),
        [BasicEditControlsEnum.Default]: async () => await _save(WikiSaveModeEnum.Major, isModal),
        [BasicEditControlsEnum.Optional]: async () => await _save(WikiSaveModeEnum.Minor, isModal),
        [WikiEditControlsEnum.Delete]: deleteWiki,
        [WikiEditControlsEnum.GoToCurrentVersion]: _goToCurrentVersion,
        [WikiEditControlsEnum.SaveAsTemplate]: _saveAsTemplate,
        [WikiEditControlsEnum.UpdateTemplate]: _updateTemplate,
        [WikiEditControlsEnum.ChooseTemplate]: _chooseTemplate,
        [WikiEditControlsEnum.TemplatesMenu]: async () => await _handleTemplatesMenu(ev),
      });

    const handler = controlsMap[action];
    if (!handler) {
      console.warn('No handler found for edit control:', action);
      return;
    }

    try {
      return await handler();
    } catch (error) {
      logErr(`Error executing edit control ${action}:`, error);
      showSonnerToast(t('errorResponse'), false);
    }
  }
  //#endregion

  //#region Miscellaneous
  async function preventEdit(id: number): Promise<boolean> {
    const { handleError } = useErrors();

    /** @note Failed to lock wiki - not 200 */
    const lockStatus = await useWikiStore().lockEdit(id);
    if (!lockStatus) {
      handleError(true, undefined, t('errorResponse'));
      return true;
    }

    /** @note Failed to lock wiki - somebody is editing it */
    if (!lockStatus.success) {
      const name = { name: lockStatus.name ?? 'Someone' };
      const lockAlert = await alertController.create({
        header: t('wiki.preventAlert.lock.title'),
        message: t('wiki.preventAlert.lock.message', name),
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            cssClass: 'custom-alert-buttons',
            handler: async () => {
              await alertController.dismiss();
            },
          },
        ],
      });

      await lockAlert.present();
      return true;
    }

    /** @note Check for isAdvanced on / off */
    const wiki = await useWikiStore().getWikiById(id);

    const isAdvancedWikiesEditor = useNetworkStore().isAdvancedWikiesEditor;
    const chosenWikiIsAdvanced = wiki?.version === WikiVersionEnum.V2;
    if (chosenWikiIsAdvanced && !isAdvancedWikiesEditor) {
      const advancedIsOffAlert = await alertController.create({
        header: t('wiki.preventAlert.advanced.title'),
        message: t('wiki.preventAlert.advanced.message'),
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            cssClass: 'custom-alert-buttons',
            handler: async () => {
              await alertController.dismiss();
            },
          },
        ],
      });

      await advancedIsOffAlert.present();
      return true;
    }

    return false;
  }

  async function openActionsMenu(event: Event, wiki: WikiModel): Promise<void> {
    const payload = { documentType: DocumentTypeEnum.Wiki, data: wiki };

    const action = isNativeMobile
      ? await docBrowserContextMenuSheet(payload)
      : await docBrowserContextMenu(event, payload);

    action.data && handleAction({ action: action.data as WikiMenuActionEnum, wiki });
  }

  async function goToHistorical(id: number, date: string, routeName: string): Promise<void> {
    try {
      const closestToDate = await useWikiStore().getHistoricalWikiByDate(id, date, WikiMajorFilterEnum.All);

      if (!closestToDate) return logWarn('Failed to get historical wiki by date');

      await useWikiStore().getWikiById(id);

      useWikiStore().setHistoricalWiki(closestToDate);

      useWikiStore().setVersionId(closestToDate.id);

      if (routeName !== ROUTES_NAME.WIKI_BY_ID) {
        await router.push({
          name: ROUTES_NAME.WIKI_BY_ID,
          params: { id: id },
        });
      }
    } catch (e) {
      logErr('An error occurred while navigating to historical wiki:', e);
    }
  }
  //#endregion

  return {
    //#region Getters
    getActionsMenuItems,
    getTemplatesMenuItems,
    getEditOptions,
    getEditControls,
    getTemplatesTableHeader,
    getCreateAccess,
    getHistoricalButtons,
    getHistoricalModifiedByAvatar,
    //#endregion

    //#region Actions
    create,
    open,
    showHistory,
    compareHistorical,
    deleteWiki,
    //#endregion

    //#region Handlers
    handleAction,
    handleEditOption,
    handleEditControl,
    //#endregion

    //#region Miscellaneous
    openActionsMenu,
    goToHistorical,
    preventEdit,
    //#endregion
  };
};
