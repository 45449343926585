<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" :width="width" :height="height">
    <g>
      <polygon fill="#AA3111" points="44,27 4,27 1,23 5,19 43,19 47,23 "></polygon>
      <path
        fill="#E6E6E6"
        d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"
      ></path>
      <path fill="#B3B3B3" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z"></path>
      <path fill="#D44519" d="M45,41H3c-1.105,0-2-0.895-2-2V23h46v16C47,40.105,46.105,41,45,41z"></path>
      <path
        fill="#FFFFFF"
        d="M16.371,30.645c0,0.878-0.259,1.556-0.776,2.033c-0.518,0.479-1.252,0.717-2.205,0.717h-0.596V36h-2.122 v-7.853h2.718c0.992,0,1.737,0.217,2.234,0.649C16.122,29.23,16.371,29.847,16.371,30.645z M12.793,31.666h0.387 c0.319,0,0.572-0.09,0.76-0.27c0.188-0.179,0.282-0.426,0.282-0.74c0-0.53-0.293-0.795-0.881-0.795h-0.548V31.666z"
      ></path>
      <path
        fill="#FFFFFF"
        d="M23.321,30.645c0,0.878-0.259,1.556-0.776,2.033c-0.518,0.479-1.252,0.717-2.205,0.717h-0.596V36h-2.122 v-7.853h2.718c0.992,0,1.737,0.217,2.234,0.649C23.072,29.23,23.321,29.847,23.321,30.645z M19.744,31.666h0.387 c0.319,0,0.572-0.09,0.76-0.27c0.188-0.179,0.282-0.426,0.282-0.74c0-0.53-0.293-0.795-0.881-0.795h-0.548V31.666z"
      ></path>
      <path fill="#FFFFFF" d="M28.037,36h-2.122v-6.117h-1.917v-1.735h5.951v1.735h-1.912V36z"></path>
      <path
        fill="#FFFFFF"
        d="M38.145,36H35.69l-1.531-2.455L32.645,36h-2.401l2.605-4.018L30.4,28.147h2.353l1.418,2.428l1.364-2.428 h2.422l-2.487,4.001L38.145,36z"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    strokewidth: {
      type: [Number, String],
      default: 1,
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
    secondaryfill: { type: String, default: '' },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>
