import { App } from '@capacitor/app';
import { isPlatform } from '@ionic/vue';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import type { RouteRecordNameGeneric, RouteRecordRaw } from 'vue-router';

import { UserRoleEnum, RouteWithSearchEnum } from '@/enums';
import { useTaskManagement, useAiAssistant, useRequirements, useWiki, useErrors } from '@/helpers';
import { logInfo } from '@/helpers/logger';
import { useI18n } from '@/i18n';
import { useUserStore, useAppStore, useGroupsStore, useCustomPageStore, useTopicStore, useDocStore } from '@/store';

declare module 'vue-router' {
  interface RouteMeta {
    isActive?: boolean;
  }
}
export const ROUTES_NAME = {
  HOME: 'Home',
  LOGIN: 'Login',
  REGISTRATION: 'Registration',
  ACTIVATION: 'Activation',
  MESSENGER_ACTIVE: 'Messenger',
  MESSENGER_ARCHIVE: 'MessengerArchive',
  MESSENGER_CHAT_BY_CHAIN: 'MessengerChatByChain',
  MESSENGER_CHAT_BY_USER: 'MessengerChatByUser',
  USERS: 'Users',
  USER_BY_ID: 'UserById',
  NOT_FOUND: 'NotFound',
  GROUPS: 'Groups',
  GROUP_BY_ID: 'GroupById',
  GROUP_DASHBOARD: 'GroupDashboard',
  NOTIFICATIONS: 'Notifications',
  FEED: 'Feed',
  POST_BY_ID: 'PostById',
  DOCS: 'Docs',
  FILE_BY_ID: 'FileById',
  PAGES: 'Pages',
  PAGE_BY_ID: 'PageById',
  PAGE_EDIT: 'PageEdit',
  CALENDAR: 'Calendar',
  SEARCH: 'Search',
  WIKIS: 'Wikis',
  WIKI_BY_ID: 'WikiById',
  WIKI_EDIT: 'WikiEdit',
  WIKI_CREATE: 'WikiCreate',
  WIKI_COMPARE: 'WikiCompare',
  TOPICS: 'Topics',
  TOPIC_BY_ID: 'TopicById',
  USAGE_RULES: 'UsageRules',
  PROJECT_BY_ID: 'ProjectById',
  PROJECTS: 'Projects',
  PROJECTS_STATISTICS: 'ProjectsStatistics',
  TASKS: 'Tasks',
  MILESTONES: 'Milestones',
  MILESTONE_BY_ID: 'MilestoneById',
  OFFICE: 'Office',
  AI_ASSISTANT: 'AiAssistant',
  IDEAS: 'Ideas',
  NETWORKS: 'Networks',
  LOADING: 'Loading',
  SETTINGS: 'Settings',
  ADMIN_DESIGN: 'AdminDesign',
  ADMIN_EMAIL_FOOTER: 'AdminEmailFooter',
  ADMIN_NETWORK_SETTINGS: 'AdminNetworkSettings',
  ADMIN_NETWORK_DOMAIN_LIST: 'AdminNetworkDomainList',
  ADMIN_BRANDING: 'AdminBranding',
  ADMIN_MOBILE_APPS: 'AdminMobileApps',
  ADMIN_USAGE_RULES: 'AdminUsageRules',
  ADMIN_PASSWORD_SETTINGS: 'AdminPasswordSettings',
  ADMIN_APPLICATIONS: 'AdminApplications',
  ADMIN_STATISTICS: 'AdminStatistics',
  ADMIN_BANNER: 'AdminBanner',
  ADMIN_TAGS: 'AdminTags',
  ADMIN_USER_MANAGEMENT: 'AdminUserManagement',
  ADMIN_INVITE_USER: 'AdminInviteUser',
  ADMIN_RESTORE_POST: 'AdminRestorePost',
  ADMIN_BADGES: 'AdminBadges',
  // DOCUMENT_VIEW: 'Document',
  UI_KIT_ICONS: 'UiKitIcons',
};

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: ROUTES_NAME.HOME,
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/login',
    name: ROUTES_NAME.LOGIN,
    component: () => import('@/views/Auth/LoginPage.vue'),
    meta: { layout: 'auth' },
  },
  ...(import.meta.env.VITE_ENABLE_REGISTRATION !== 'false'
    ? [
        {
          path: '/registration',
          name: ROUTES_NAME.REGISTRATION,
          component: () => import('@/views/Auth/RegistrationPage.vue'),
          meta: { layout: 'auth' },
        },
        {
          path: '/activation/:id',
          name: ROUTES_NAME.ACTIVATION,
          component: () => import('@/views/Auth/RegistrationPage.vue'),
          meta: { layout: 'auth' },
        },
      ]
    : []),
  {
    path: '/messenger',
    name: ROUTES_NAME.MESSENGER_ACTIVE,
    component: () => import('@/views/Messenger/MessengerPage.vue'),
    meta: { isMessenger: true, isArchive: false },
  },
  {
    path: '/messenger/archive',
    name: ROUTES_NAME.MESSENGER_ARCHIVE,
    component: () => import('@/views/Messenger/MessengerPage.vue'),
    meta: { isMessenger: true, isArchive: true },
  },
  {
    path: '/users',
    name: ROUTES_NAME.USERS,
    component: () => import(`@/views/Users/UsersPage.vue`),
    props: (route) => ({ searchText: route.query.searchText }),
  },
  {
    path: '/user/:id',
    name: ROUTES_NAME.USER_BY_ID,
    component: () => import('@/views/Users/UserPage.vue'),
  },
  {
    path: '/messenger/chain/:id',
    name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
    component: () => import('@/views/Messenger/ChatPage.vue'),
    meta: { type: 'chain', isMessenger: true },
  },
  {
    path: '/messenger/user/:id',
    name: ROUTES_NAME.MESSENGER_CHAT_BY_USER,
    component: () => import('@/views/Messenger/ChatPage.vue'),
    meta: { type: 'user', isMessenger: true },
  },
  {
    path: '/groups',
    name: ROUTES_NAME.GROUPS,
    component: () => import('@/views/Groups/GroupsPage.vue'),
    props: (route) => ({ searchText: route.query.searchText }),
  },
  {
    path: '/group/:id',
    name: ROUTES_NAME.GROUP_BY_ID,
    component: () => import('@/views/Groups/GroupPage.vue'),
  },
  {
    path: '/group/:id/dashboard',
    name: ROUTES_NAME.GROUP_DASHBOARD,
    component: () => import('@/views/Groups/GroupDashboardPage.vue'),
  },
  {
    path: '/notifications',
    name: ROUTES_NAME.NOTIFICATIONS,
    component: () => import('@/views/Notifications/NotificationsPage.vue'),
  },
  {
    path: '/feed',
    name: ROUTES_NAME.FEED,
    component: () => import('@/views/Feed/FeedPage.vue'),
  },
  {
    path: '/ideas',
    name: ROUTES_NAME.IDEAS,
    component: () => import('@/views/Ideas/IdeasPage.vue'),
  },
  {
    path: '/post/:id',
    name: ROUTES_NAME.POST_BY_ID,
    component: () => import('@/views/FeedItem/FeedItemPage.vue'),
  },
  {
    path: '/docs',
    name: ROUTES_NAME.DOCS,
    component: () => import(`@/views/Docs/DocsPage.vue`),
  },
  {
    path: '/file/:id',
    name: ROUTES_NAME.FILE_BY_ID,
    component: () => import(`@/views/File/FilePage.vue`),
  },
  {
    path: '/pages',
    name: ROUTES_NAME.PAGES,
    component: () => import(`@/views/CustomPages/CustomPages.vue`),
    meta: { requiresStandardUserRights: true },
  },
  {
    path: '/page/:id',
    name: ROUTES_NAME.PAGE_BY_ID,
    component: () => import(`@/views/CustomPages/CustomPage.vue`),
  },
  {
    path: '/page/:id/edit',
    name: ROUTES_NAME.PAGE_EDIT,
    component: () => import(`@/views/CustomPages/CustomPageEdit.vue`),
    meta: { requiresStandardUserRights: true },
  },
  {
    path: '/calendar',
    name: ROUTES_NAME.CALENDAR,
    component: () => import('@/views/Calendar/CalendarPage.vue'),
  },
  {
    path: '/wikis',
    name: ROUTES_NAME.WIKIS,
    component: () => import('@/views/Wikis/WikisPage.vue'),
  },
  {
    path: '/wiki/:id/:versionId?',
    name: ROUTES_NAME.WIKI_BY_ID,
    component: () => import('@/views/Wikis/WikiPage.vue'),
  },
  {
    path: '/wiki/edit/:id',
    name: ROUTES_NAME.WIKI_EDIT,
    component: () => import(`@/views/Wikis/WikiEditPage.vue`),
  },
  {
    path: '/wiki/create',
    name: ROUTES_NAME.WIKI_CREATE,
    component: () => import(`@/views/Wikis/WikiCreatePage.vue`),
  },
  {
    path: '/wiki/compare/:id',
    name: ROUTES_NAME.WIKI_COMPARE,
    component: () => import(`@/views/Wikis/WikiComparePage.vue`),
    props: (route) => ({ ...route.params, ...route.query }),
  },
  {
    path: '/topics',
    name: ROUTES_NAME.TOPICS,
    component: () => import('@/views/Topics/TopicsPage.vue'),
  },
  {
    path: '/topic/:id',
    name: ROUTES_NAME.TOPIC_BY_ID,
    component: () => import(`@/views/Topics/TopicPage.vue`),
  },
  {
    path: '/search',
    name: ROUTES_NAME.SEARCH,
    component: () => import('@/views/Search/SearchPage.vue'),
  },
  {
    path: '/admin/design',
    name: ROUTES_NAME.ADMIN_DESIGN,
    component: () => import('@/views/Admin/Design/AdminDesignPage.vue'),
    meta: { requiresModeratorRights: true },
  },
  {
    path: '/admin/email-footer',
    name: ROUTES_NAME.ADMIN_EMAIL_FOOTER,
    component: () => import('@/views/Admin/AdminEmailFooterPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/network-settings',
    name: ROUTES_NAME.ADMIN_NETWORK_SETTINGS,
    component: () => import('@/views/Admin/NetworkSettings/AdminNetworkSettingsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/domain-list',
    name: ROUTES_NAME.ADMIN_NETWORK_DOMAIN_LIST,
    component: () => import('@/views/Admin/NetworkSettings/AdminNetworkDomainListPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/branding',
    name: ROUTES_NAME.ADMIN_BRANDING,
    component: () => import('@/views/Admin/NetworkSettings/AdminBrandingPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/mobile-apps',
    name: ROUTES_NAME.ADMIN_MOBILE_APPS,
    component: () => import('@/views/Admin/NetworkSettings/AdminMobileAppsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/usage-rules',
    name: ROUTES_NAME.ADMIN_USAGE_RULES,
    component: () => import('@/views/Admin/AdminUsageRulesPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/password-settings',
    name: ROUTES_NAME.ADMIN_PASSWORD_SETTINGS,
    component: () => import('@/views/Admin/AdminPasswordSettingsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/apps',
    name: ROUTES_NAME.ADMIN_APPLICATIONS,
    component: () => import('@/views/Admin/AdminApplicationsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/statistics',
    name: ROUTES_NAME.ADMIN_STATISTICS,
    component: () => import('@/views/Admin/AdminStatisticsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/banner',
    name: ROUTES_NAME.ADMIN_BANNER,
    component: () => import('@/views/Admin/AdminBannerPage.vue'),
    meta: { requiresAdminRights: true },
  },

  {
    path: '/admin/tags',
    name: ROUTES_NAME.ADMIN_TAGS,
    component: () => import('@/views/Admin/AdminTagsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/user-management',
    name: ROUTES_NAME.ADMIN_USER_MANAGEMENT,
    component: () => import('@/views/Admin/AdminUserManagementPage.vue'),
    meta: { requiresModeratorRights: true },
  },
  ...(import.meta.env.VITE_ENABLE_ADMIN_REGISTRATION !== 'false'
    ? [
        {
          path: '/admin/user-management/new',
          name: ROUTES_NAME.ADMIN_INVITE_USER,
          component: () => import('@/views/Admin/AdminInviteUserPage.vue'),
          meta: { requiresAdminRights: true },
        },
      ]
    : []),
  {
    path: '/admin/restore-post',
    name: ROUTES_NAME.ADMIN_RESTORE_POST,
    component: () => import('@/views/Admin/AdminRestorePostPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/badges',
    name: ROUTES_NAME.ADMIN_BADGES,
    component: () => import('@/views/Admin/AdminBadgesPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/usage-rules',
    name: ROUTES_NAME.USAGE_RULES,
    component: () => import('@/views/Networks/UsageRules/UsageRulesView.vue'),
  },
  {
    path: '/projects',
    name: ROUTES_NAME.PROJECTS,
    component: () => import('@/views/Projects/ProjectsPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  {
    path: '/project/:projectId',
    name: ROUTES_NAME.PROJECT_BY_ID,
    component: () => import('@/views/Projects/ProjectPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  {
    path: '/project/:projectId/milestones',
    name: ROUTES_NAME.MILESTONES,
    component: () => import('@/views/Projects/MilestonesPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  /* {
    path: '/projects/statistics',
    name: ROUTES_NAME.PROJECTS_STATISTICS,
    component: () => import('@/views/Projects/StatisticsPage.vue'),
    meta: { requiresProjectsPermission: true },
  }, */
  {
    path: '/tasks',
    name: ROUTES_NAME.TASKS,
    component: () => import('@/views/Projects/TasksPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  {
    path: '/account/settings',
    name: ROUTES_NAME.SETTINGS,
    component: () => import('@/views/Settings/SettingsPage.vue'),
  },
  /* {
    path: '/project/:projectId/milestone/:milestoneId',
    name: ROUTES_NAME.MILESTONE_BY_ID,
    component: () => import('@/views/Projects/MilestonePage.vue'),
  }, */
  {
    path: '/office/:payload',
    name: ROUTES_NAME.OFFICE,
    component: () => import('@/views/Office/OfficeView.vue'),
    props: (route) => ({ ...route.params, ...route.query }),
  },
  {
    path: '/ai-assistant',
    name: ROUTES_NAME.AI_ASSISTANT,
    component: () => import('@/views/AiAssistant/AiAssistantPage.vue'),
    meta: { requiresAiAssistantPermission: true },
  },

  ...(import.meta.env.DEV
    ? [
        {
          path: '/networks',
          name: ROUTES_NAME.NETWORKS,
          component: () => import('@/views/Networks/NetworksPage.vue'),
        },
        {
          path: '/ui-kit/icons',
          name: ROUTES_NAME.UI_KIT_ICONS,
          component: () => import('@/views/UiKit/UiKitIconsPage.vue'),
        },
      ]
    : []),
  {
    path: '/loading',
    name: ROUTES_NAME.LOADING,
    component: () => import('@/views/Common/LoadingPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: ROUTES_NAME.NOT_FOUND,
    component: () => import('@/views/Common/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

/**
 * @description Sets keepSearchQuery param for pages with search bar
 * @param {RouteRecordNameGeneric} toRouteName - route name to which routing is processing
 * @param {RouteRecordNameGeneric} fromRouteName - route name from which routing is processing
 */
const _setKeepSearchQueryState = (toRouteName: RouteRecordNameGeneric, fromRouteName: RouteRecordNameGeneric) => {
  const routeHandlers: Record<RouteWithSearchEnum, () => void> = {
    [RouteWithSearchEnum.GROUPS]: () => {
      useGroupsStore().$patch({
        keepSearchQuery: fromRouteName === ROUTES_NAME.GROUP_BY_ID,
      });
    },
    [RouteWithSearchEnum.USERS]: () => {
      useUserStore().$patch({
        keepSearchQuery: fromRouteName === ROUTES_NAME.USER_BY_ID,
      });
    },
    [RouteWithSearchEnum.PAGES]: () => {
      useCustomPageStore().$patch({
        keepSearchQuery: fromRouteName === ROUTES_NAME.PAGE_BY_ID,
      });
    },
    [RouteWithSearchEnum.TOPICS]: () => {
      useTopicStore().$patch({
        keepSearchQuery: fromRouteName === ROUTES_NAME.TOPIC_BY_ID,
      });
    },
  };

  const handler = routeHandlers[toRouteName as RouteWithSearchEnum];
  if (!handler) {
    return;
  }
  handler();
};

router.beforeEach(async (to, from): Promise<boolean> => {
  const appStore = useAppStore();
  const docStore = useDocStore();
  const { handleError } = useErrors();
  const isAndroid = isPlatform('android') && !isPlatform('mobileweb');

  try {
    /**
     * NOTE: If link contains anything we need to store it if it's not already stored and redirect user to login page
     * @see src/helpers/useUserFlow.ts - _toApp - line 241
     * @see src/views/Auth/LoginPage.vue - onSubmit - line 351
     *  @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1204
     */
    if (
      !appStore.isAuth() &&
      to.name !== ROUTES_NAME.LOGIN &&
      to.name !== ROUTES_NAME.REGISTRATION &&
      to.name !== ROUTES_NAME.ACTIVATION
    ) {
      await appStore.token(true, true);
      if (appStore.isAuth()) {
        return true;
      } else {
        to.name !== ROUTES_NAME.HOME && appStore.setRedirectUrl(to.fullPath);
        return false;
      }
    }

    /* NOTE: If going to wiki edit page => check if we should prevent it */
    if (to.name === ROUTES_NAME.WIKI_EDIT) {
      const preventRoute = await useWiki().preventEdit(Number(to.params.id));
      if (preventRoute) return false;
    }

    /* NOTE: Setting keepSearchQuery param for pages with search bar */
    _setKeepSearchQueryState(to.name, from.name);

    /* NOTE: If clearCache query param is present => modifying to.fullPath to remove it */
    if (to.query.clearCache === 'true') {
      const trimmedUrl = to.fullPath.replace(/\?clearCache=true/g, '');
      to.fullPath = trimmedUrl;
    }

    /* NOTE: If middle button is clicked => open the link in a new tab AND prevent the navigation */
    if (appStore.isMiddleButtonClicked) {
      appStore.$patch((state) => {
        state.isMiddleButtonClicked = false;
      });

      window.open(`${to.fullPath}`, '_blank');
      return false;
    }

    /* NOTE: If going to home page => check if it's the same as the homePage. If so AND isAndroid => exit the app */
    if (to.name === ROUTES_NAME.HOME && from.name === appStore.homePage.name) {
      if (isAndroid) {
        logInfo('to.name === home && from.name === homePage && isAndroid => exiting app...'); //! DEBUG
        await App.exitApp();
        return false;
      }

      await router.push(appStore.homePage);
      return true;
    }

    /* NOTE: If going to docs page => keep the docbrowser state for the file, office, or wiki page */
    if (from.name === ROUTES_NAME.DOCS) {
      docStore.$patch({
        keepDocbrowserState:
          to.name === ROUTES_NAME.FILE_BY_ID || to.name === ROUTES_NAME.OFFICE || to.name === ROUTES_NAME.WIKI_BY_ID,
      });
    }

    return true;
  } catch (error) {
    const text = `Error while performing route navigation. Failed to navigate from ${String(from.name)} to ${String(to.name)}`;
    handleError(false, error, text);
    return false;
  }
});

router.beforeResolve(async (to) => {
  const { handleError } = useErrors();
  const taskManagementHelper = useTaskManagement();
  const aiAssistantHelper = useAiAssistant();

  const userStore = useUserStore();
  const userRole = userStore.current?.roleId ?? 0;

  if (await useRequirements().requirementsCheckInProcess()) {
    const { t } = useI18n();
    handleError(true, undefined, t('read.required'));
    return false;
  }

  if (
    (to.meta.requiresModeratorRights && userRole < UserRoleEnum.Moderator) ||
    (to.meta.requiresAdminRights && userRole < UserRoleEnum.Administrator) ||
    (to.meta.requiresStandardUserRights && userRole < UserRoleEnum.User) ||
    (to.meta.requiresProjectsPermission && !taskManagementHelper.getAccessToTaskManagement()) ||
    (to.meta.requiresAiAssistantPermission && !aiAssistantHelper.getAccessToAiAssistant())
  ) {
    router.push({ name: ROUTES_NAME.FEED });
  } else {
    return true;
  }
});

router.afterEach((to, from, failure) => {
  const { handleError } = useErrors();

  if (failure) {
    const eText = `Error while performing route navigation. Failed to navigate from ${String(from.name)} to ${String(to.name)}`;
    handleError(false, failure, eText);
  }
});

export default router;
