<template>
  <div class="block">
    <div class="top">
      <ion-skeleton-text class="avatar" />
      <ion-skeleton-text class="info" />
      <ion-skeleton-text class="dots" />
    </div>

    <div class="middle">
      <ion-skeleton-text animated />
    </div>

    <div class="controls">
      <ion-skeleton-text class="control like" />
      <ion-skeleton-text class="control" />
      <ion-skeleton-text class="control read" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonSkeletonText } from '@ionic/vue';
</script>

<style scoped lang="scss">
.block {
  background-color: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);
  margin-bottom: app-padding(lg);
  padding: app-padding(lg);

  .top {
    display: flex;
    align-items: center;

    .avatar {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      margin-right: app-padding(md);
    }

    .info {
      border-radius: app-radius(md);
      width: 20%;
      height: 2.5rem;
    }

    .dots {
      border-radius: app-radius(md);
      width: 1.5rem;
      height: 1.5rem;
      margin-left: auto;
    }
  }

  .middle {
    border-radius: app-radius(md);
    margin-top: app-padding(md);
    margin-bottom: app-padding(md);
    width: 100%;
    height: 6rem;
  }

  .controls {
    display: flex;
    justify-content: start;
    align-items: center;

    .control {
      border-radius: app-radius(md);
      width: 4rem;
      height: 2.5rem;
    }

    .like {
      margin-right: app-padding(md);
    }

    .read {
      margin-left: auto;
    }
  }
}
</style>
